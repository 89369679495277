import React, { useEffect, useState } from "react";
import styles from "./Category.module.css";
import defaultImage from "../../Assest/img/defaultProfile.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { storeDetails } from "../../api/ecom";
import { useLocation } from "react-router-dom";

function Category() {
  const navigate = useNavigate();
  const location = useLocation();

  const vendorId = location.state || {};

  const [activeTab, setActiveTab] = useState("new");
  const [vendorDetail, setVendorDetail] = useState({ categoriesForVendor: [], vendorDetails: null });
  const [searchTerm, setSearchTerm] = useState("");


  console.log(vendorId)
  
  const token = useSelector(state => state.login.token);

  const categoryList =async (searchTerm =undefined) => {
    const params = {
      search: searchTerm,
      isNew: activeTab === "new" ? 1 : 0,
    };
    const response = await storeDetails(token, vendorId.vendorId, params)
    console.log(response)
    setVendorDetail(response.data)
  }
  useEffect(() => {
    
      
   
     categoryList()
   },[activeTab])

   const handleSearchInputChange = (e) => {
    e.preventDefault();

    setSearchTerm(e.target.value);
    categoryList(e.target.value);
  };

   if (!vendorDetail.vendorDetails) {
    return <div>Loading...</div>;  // Return loading state until vendorDetails are available
  }

  return (
    <div className={styles.container}>
      <div className={styles.cardContainer}>
              <div className={styles.shopInfo}>
                  
          <div className={styles.shopProfile}>
            <img
              src={defaultImage}
              alt="Store"
              className={styles.shopIcon}
            />
          </div>

          <div className={styles.shopDetails}>
           
                <h2>{vendorDetail.vendorDetails.storeName}</h2>
                <div className={styles.shopDetail}>
                  <button className={styles.showMobileButton}>
                    {vendorDetail.vendorDetails.phone}
                  </button>
                  <span> {vendorDetail.allProductCountOfVendor} Products</span>
                </div>
            <div className={styles.toggleContainer}>
          <button
            className={`${styles.toggleButton} ${
              activeTab === "new" ? styles.activeToggle : ""
            }`}
            onClick={() => setActiveTab("new")}
          >
            New Items
          </button>
          <button
            className={`${styles.toggleButton} ${
              activeTab === "used" ? styles.activeToggle : ""
            }`}
            onClick={() => setActiveTab("used")}
          >
            Used Items
          </button>
            </div>
                  </div>
                  
          <div className={styles.searchContainer}>
            <form
              className={`d-flex ${styles.search}`}
              onSubmit={(e) => e.preventDefault()}
            >
              <input
                className={`form-control text-custom me-2 w-30 ${styles.textCustom}`}
                type="search"
                placeholder="Search"
                aria-label="Search"
                  onChange={handleSearchInputChange}
                  value={searchTerm}
              />
            </form>{" "}
          </div>
        </div>

        <div className={styles.productsGrid}>
          {vendorDetail.categoriesForVendor.map((product) => (
            <div key={product.id} className={styles.productCard}>
              <img
                src={product.img}
                alt={product.name}
                className={styles.productImage}
              />
              <h3>{product.name}</h3>
              <button className={styles.viewMore} onClick={() => navigate('/ecominventory', { state: {
                      categoryId: product.id,
                      vendorId: vendorId.vendorId,
                    }, })}>View more</button>
            </div>
          ))}
             
           
        </div>
      </div>
    </div>
  );
}

export default Category;
