import React, { useMemo, useState, useEffect } from "react";
import styles from "./OrderDetail.module.css";
import { useLocation } from "react-router";
import { useTable, usePagination } from "react-table";
import { Column } from "./ColumnOrderDetails";
import { useSelector } from "react-redux";
import {
  addNewDateForOrder,
  dispatchOrder,
  orderDetails,
} from "../../api/order";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import ReturnOrder from "./RuturnOrder";
import ExtraCharge from "./ExtraCharge";
import DamageOrder from "./DamageOrder";
import MarkCompleteOrder from "./markCompleteOrder";
import Challan from "./Challan";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; 
import "react-date-range/dist/theme/default.css"; 
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { parse, format } from "date-fns";
import { cancelOrder } from "../../api/order";
import LoadingSpinner from "../Home/LodingSpinner";

function OrderDetail() {
  const location = useLocation();
  const navigate = useNavigate();
  const { orderId } = location.state || {};

  const token = useSelector((state) => state.login.token);
  const [isReturnModalOpen, setReturnModalOpen] = useState(false);
  const [isExtraChargeModalOpen, setExtraChargeModalOpen] = useState(false);
  const [isDamageModalOpen, setDamageModalOpen] = useState(false);
  const [isCompleteModalOpen, setCompleteModalOpen] = useState(false);
  const [isChallanModalOpen, setChallanModalOpen] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [orderEndDate, setOrderEndDate] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [order, setOrder] = useState({
    cartItems: [],
    orderDetails: {},
    extraCharges: [],
    returnedItems: [],
    damagedItems: [],
  });

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  //fetch order DATA
  const fetchOrderData = async () => {
    try {
      const response = await orderDetails(token, orderId);
      setOrder(response.data);
      setOrderEndDate(order.orderDetails?.endDate || "")
    }catch (error) {
      console.error("Failed to fetch order details:", error);
    }
  }

  //cancel order
  const handleCancelOrder = async (orderId) => {
    try {
      setIsDeleting(true);
      await cancelOrder(token, orderId);
      toast.success('Order cancelled successfully');
      
    } catch (error) {
      console.log('Order cancellation failed');
    } finally {
      setIsDeleting(false);
      navigate('/order')
    }
  };


  useEffect(() => {
    
        fetchOrderData()
    
  }, [token , orderEndDate ]);

  const handleSelect = (ranges) => {
    const updatedRange = ranges.selection;
    setDateRange([updatedRange]);
  };

     // Format dates to MM-DD-YYYY
     const formatToCustomDate = (dateString) => {
      const date = new Date(dateString);
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
      const day = String(date.getDate()).padStart(2, "0");
      const year = date.getFullYear();

      return `${year}-${month}-${day}`;
    };

  const handleConfirmDateRange = () => {
    console.log("dateRange:", dateRange); // Check the entire object
    const newStartDate = dateRange.map((e) => e.startDate);
    const newEndDate = dateRange.map((e) => e.endDate);

    console.log("newStartDate:", newStartDate); // Log newStartDate
    console.log("newEndDate:", newEndDate); // Log newEndDate

 
    // Format start and end dates
    const formattedStartDate = newStartDate.length
      ? formatToCustomDate(newStartDate[0])
      : "";
    const formattedEndDate = newEndDate.length
      ? formatToCustomDate(newEndDate[0])
      : "";

    console.log("Confirmed Dates:", formattedStartDate, formattedEndDate);

    if (formattedStartDate || formattedEndDate) {
      addNewDateForPending(formattedStartDate, formattedEndDate);
    } else {
      return;
    }
  };

  // Mock function for API call
  const addNewDateForPending = async (startDate, endDate) => {
    console.log("API called with:", startDate, endDate);
    // Perform API call here
    try {
      await addNewDateForOrder(token, orderId, startDate, endDate);
      fetchOrderData();
      toast.success("Dates updated successfully");
    } catch (error) {
      toast.error("Failed to update dates. Please try again.");
      console.error(error);
    }
  };


  const updateData = async () => {
    try {
      const response = await orderDetails(token, orderId);
      setOrder(response.data);
    } catch (error) {
      console.error("Failed to fetch order details:", error);
    }
  };


  const handleDispatchOrder = async () => {
    try {
      await dispatchOrder(token, orderId);
      toast.success("Order dispatched successfully");
      navigate("/order");
    } catch (error) {
      toast.error("Failed to dispatch order. Please try again.");
    }
  };

  const addMoreInventory = () => {
    navigate("/order/addItem", {
      state: { orderId: order.orderDetails.orderId, isEditMode: true , newItemStartDate:order.orderDetails.startDate , status :order.orderDetails.status},
    });
  };

  const memoizedColumns = useMemo(() => Column, []);
  const memoizedData = useMemo(() => order.cartItems, [order.cartItems]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    pageOptions,
    state: { pageIndex },
  } = useTable(
    {
      columns: memoizedColumns,
      data: memoizedData,
      initialState: { pageIndex: 0, pageSize: 9 },
    },
    usePagination
  );

  const memoizedReturnColumns = useMemo(() => Column, []);
  const memoizedReturnData = useMemo(
    () => order.returnedItems,
    [order.returnedItems]
  );
  const {
    getTableProps: getReturnTableProps,
    getTableBodyProps: getReturnTableBodyProps,
    headerGroups: returnHeaderGroups,
    prepareRow: prepareReturnRow,
    page: returnPage,
    canPreviousPage: canPreviousReturnPage,
    canNextPage: canNextReturnPage,
    nextPage: nextReturnPage,
    previousPage: previousReturnPage,
    pageOptions: returnPageOptions,
    state: { pageIndex: returnPageIndex },
  } = useTable(
    {
      columns: memoizedReturnColumns,
      data: memoizedReturnData,
      initialState: { pageIndex: 0, pageSize: 9 },
    },
    usePagination
  );

  const memoizedDamageColumns = useMemo(() => Column, []);
  const memoizedDamageData = useMemo(
    () => order.damagedItems,
    [order.damagedItems]
  );
  const {
    getTableProps: getDamageTableProps,
    getTableBodyProps: getDamageTableBodyProps,
    headerGroups: damageHeaderGroups,
    prepareRow: prepareDamageRow,
    page: damagePage,
    canPreviousPage: canPreviousDamagePage,
    canNextPage: canNextDamagePage,
    nextPage: nextDamagePage,
    previousPage: previousDamagePage,
    pageOptions: damagePageOptions,
    state: { pageIndex: damagePageIndex },
  } = useTable(
    {
      columns: memoizedDamageColumns,
      data: memoizedDamageData,
      initialState: { pageIndex: 0, pageSize: 9 },
    },
    usePagination
  );

  const handleReturnItem = () => {
    setReturnModalOpen(true);
  };
  const handleCloseReturnModal = () => {
    setReturnModalOpen(false);
  };
  const handleCloseExtraChargeModal = () => {
    setExtraChargeModalOpen(false);
  };
  const handleOpenExtraChargeModal = () => {
    setExtraChargeModalOpen(true);
  };
  const handleDamageItem = () => {
    setDamageModalOpen(true);
  };
  const handleCloseDamageItem = () => {
    setDamageModalOpen(false);
  };
  const handleMarkCompleteOrder = () => {
    setCompleteModalOpen(true);
  };
  const handleMarkCloseCompleteOrder = () => {
    setCompleteModalOpen(false);
  };
  const handleChallanModal = () => {
    setChallanModalOpen(true);
  };
  const handleChallanMOdalClose = () => {
    setChallanModalOpen(false);
  };
  const handleShow = () => {
    setShowDate(!showDate);
  };
  const HandleOpenChallanPdf = () => {
    const link = order.orderDetails.challan;
    window.open(link);
  };
 
  const HandleOpenInvoicePdf = () => {
    const link = order.orderDetails.invoice;
    console.log(link);
    window.open(link);
  };
  const handleSetEndDate =async (date) => {
    const newEndDate = formatToCustomDate(date)
    await addNewDateForOrder(token, orderId, '', newEndDate);
     toast.success("End date updated successfully");
 await fetchOrderData()
    console.log(newEndDate)
  }

  const parseAndFormatDate = (dateString) => {
    const cleanedDate = dateString.replace(/\d+(st|nd|rd|th)/, (match) => match.slice(0, -2));
    const parsedDate = parse(cleanedDate, "d MMM yy", new Date());
    return format(parsedDate, "yyyy-MM-dd");
  };
  
  return (
    <div className={styles.container}>
      <div className={styles.cardContainer}>
        <div className={styles.head}>
          {order.orderDetails.status === "dispatched" ? (
            <p>Dispatch Order</p>
          ) :order.orderDetails.status === "completed" ? (
            <p>Completed Order</p>
          ) : (
            <p> Pending Order</p>
          )}
        </div>
        <div className={styles.info}>
          <div>
            <label>Party Name</label>
            <input value={order.orderDetails?.partyName || ""} />
          </div>
          {order.orderDetails?.status === "pending" && (
            <div className={styles.date}>
              <div className={styles.edit}>
                <label>Start Date</label>
                <input
                  value={order.orderDetails?.startDate || ""}
                  onClick={handleShow}
                />
              </div>

              <div className={styles.edit}>
                <label>End Date</label>
                <input
                                    value={order.orderDetails?.endDate || ""}

                  onClick={handleShow}
                />
              </div>
            </div>
          )}

          {order.orderDetails?.status === "dispatched" && (
            <div className={styles.date}>
              <div className={styles.datediv}>
                <label>Start Date</label>
                <input value={order.orderDetails?.startDate || ""} />
              </div>

              <div className={styles.edit}>
                <label>End Date</label>
                <div>
                <ReactDatePicker
                value={orderEndDate}
                onChange={(date) => {
              handleSetEndDate(date);
                  // setOpen(false); 
                }}
                  minDate={parseAndFormatDate(order.orderDetails?.startDate || "")}
                placeholderText="Select an end date"
                dateFormat="yyyy-MM-dd"              
                  />
                  </div>
              </div>
            </div>
          )}
        </div>
        {showDate && (
          <div
            className={styles.range}
            style={{
              position: "relative",
              padding: "20px",
              marginBottom: "2rem",
            }}
          >
            <DateRangePicker
              ranges={dateRange}
              onChange={handleSelect}
              minDate={new Date()}
              showDateDisplay={false}
              staticRanges={[]}
              inputRanges={[]}
            />
            <button
              className={styles.confirmButton}
              onClick={handleConfirmDateRange}
            >
              Confirm Date Range
            </button>
          </div>
        )}

        {order.orderDetails.status === "dispatched" && (
          <div className={styles.extraDetail}>
            <button onClick={handleOpenExtraChargeModal}>Extra Charge</button>
            <button onClick={handleReturnItem}>Return Item</button>
            <button onClick={handleDamageItem}>Damage Item</button>
          </div>
        )}
        <div className={styles.selectedItem}>
          <p>Selected Item List</p>
        </div>
        <div className={styles.table}>
          <table
            {...getTableProps()}
            className="table "
            id={order.orderDetails.orderId}
            key={order.orderDetails.orderId}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()} key={column.id}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.length > 0 ? (
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} key={row.id}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()} key={cell.column.id}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={memoizedColumns.length}>No Data Available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className={styles.additem} onClick={addMoreInventory}>
          <button>Add More Inventory</button>
        </div>

        {/* Table for Return Items */}
        {memoizedReturnData.length > 0 && <>
     
  <div className={styles.selectedItem}>
  <p>Return Item List</p>
</div>
          <div className={styles.returnTable}>
            <table
              {...getReturnTableProps()}
              className="table"
              id="returned-items"
              key="returned-items"
            >
              <thead>
                {returnHeaderGroups.map((headerGroup) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    key={headerGroup.id}
                  >
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()} key={column.id}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getReturnTableBodyProps()}>
                {returnPage.length > 0 ? (
                  returnPage.map((row) => {
                    prepareReturnRow(row);
                    return (
                      <tr {...row.getRowProps()} key={row.id}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()} key={cell.column.id}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={memoizedReturnColumns.length}>
                      No Data Available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
         </>}

        {/* Table for Damage Items */}
        {memoizedDamageData.length > 0 && <>
        <div className={styles.selectedItem}>
        <p>Damage Item List</p>
      </div>
          <div className={styles.returnTable}>
            <table
              {...getDamageTableProps()}
              className="table"
              id="returned-items"
              key="returned-items"
            >
              <thead>
                {damageHeaderGroups.map((headerGroup) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    key={headerGroup.id}
                  >
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()} key={column.id}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getDamageTableBodyProps()}>
                {damagePage.length > 0 ? (
                  damagePage.map((row) => {
                    prepareDamageRow(row);
                    return (
                      <tr {...row.getRowProps()} key={row.id}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()} key={cell.column.id}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={memoizedDamageColumns.length}>
                      No Data Available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </>}

        <div className={styles.paymentInfo}>
          <div className={styles.column}>
            <div className={styles.row}>
              <p> Subtotal </p>
              <span>₹{order.orderDetails?.totalCartAmount || 0}</span>
            </div>
            <div className={styles.row}>
              <p> Advance </p>
              <span>₹{order.orderDetails?.advance || 0}</span>
            </div>
            <div className={styles.row}>
              <p> Discount </p>
              <span>₹{order.orderDetails?.discount || 0}</span>
            </div>
            <div className={styles.row}>
              <p> gst </p>
              <span>₹{order.orderDetails?.gst || 0}</span>
            </div>
            <div className={styles.row}>
              <p> Extra Charge </p>
              <span>
                ₹
                {order.extraCharges.length
                  ? order.extraCharges.map((e) => e.totalAmount)
                  : 0}
              </span>
            </div>
            <div className={styles.row}>
              <p> Total </p>
              <span>₹{order.orderDetails?.finalAmount || 0}</span>
            </div>
          </div>
        </div>

        {order.orderDetails?.status === "pending" && (
          <div className={styles.saveCancel}>
            <button className={styles.cancel} onClick={()=>handleCancelOrder(order.orderDetails.orderId)} disabled={isDeleting}
            >Cancel Order</button>
            <button className={styles.save} onClick={handleDispatchOrder}>
              Dispatch
            </button>
          </div>
        )}
        
      {/* If you want a full-screen loader */}
      {isDeleting && (
  <div className={styles.overlayLoader}>  // Using styles object
    <LoadingSpinner size="large" />
  </div>
)}
        {order.orderDetails?.status === "dispatched" && (
          <div className={styles.saveCancel}>
            <button className={styles.cancels} onClick={HandleOpenChallanPdf}>
              View Challan
            </button>

            <button className={styles.save} onClick={handleMarkCompleteOrder}>
              Complete
            </button>
          </div>
        )}
        {order.orderDetails?.status === "completed" && (
          <div className={styles.saveCancel}>
            <button className={styles.save} onClick={HandleOpenChallanPdf}>
              Challan
            </button>

            <button className={styles.save} onClick={HandleOpenInvoicePdf}>Invoice</button>
          </div>
        )}
      </div>
      <ReturnOrder
        isOpen={isReturnModalOpen}
        onClose={handleCloseReturnModal}
        orderId={orderId}
        cartItemId={order.cartItems.cartItemId}
        updateData={updateData}
      />
      <ExtraCharge
        isOpen={isExtraChargeModalOpen}
        onClose={handleCloseExtraChargeModal}
        orderId={orderId}
        updateData={updateData}
      />
      <DamageOrder
        isOpen={isDamageModalOpen}
        onClose={handleCloseDamageItem}
        orderId={orderId}
        updateData={updateData}
      />
      <MarkCompleteOrder
        isOpen={isCompleteModalOpen}
        onClose={handleMarkCloseCompleteOrder}
        orderId={orderId}
        updateData={updateData}
      />
      <Challan
        isOpen={isChallanModalOpen}
        onClose={handleChallanMOdalClose}
        orderId={orderId}
        updateData={updateData}
      />
    </div>
  );
}

export default OrderDetail;