import React, { useEffect, useState } from "react";
import styles from "./Wishlist.module.css";
import { useSelector } from "react-redux";
import { getWishlist, removeWishlist } from "../../api/ecom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function Wishlist() {
  const navigate = useNavigate();
  const token = useSelector((state) => state.login.token);
  const [searchTerm, setSearchTerm] = useState("");
  const [allWishlist, setAllWishlist] = useState([]);

  // Fetch wishlist from API
  const fetchWishlist = async () => {
    try {
      const response = await getWishlist(token);
      setAllWishlist(response.data.whishListedProducts);
    } catch (error) {
      console.error("Error fetching wishlist:", error);
      toast.error("Failed to fetch wishlist");
    }
  };

  useEffect(() => {
    fetchWishlist();
  }, [token]);

  // Handle search input change
  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Navigate to manage inventory
  const handleManageInventory = () => {
    navigate('/manageInventory');
  };

  // Remove from wishlist
  const handleRemoveWishlist = async (id) => {
    try {
      await removeWishlist(token, id);
      toast.success("Wishlist removed successfully");
      fetchWishlist(); // Refresh the wishlist after removal
    } catch (error) {
      console.error("Error removing wishlist:", error);
      toast.error("Failed to remove from wishlist");
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.cardContainer}>
        <div className={styles.searchContainer}>
          <div className={styles.wishlistTitle}>
            <h1>{allWishlist && allWishlist.length} items</h1>
          </div>
          <div className={styles.searchInput}>
            <form className={`d-flex ${styles.search}`} onSubmit={(e) => e.preventDefault()}>
              <input
                className={`form-control text-custom me-2 w-30 ${styles.textCustom}`}
                type="search"
                placeholder="Search"
                aria-label="Search"
                onChange={handleSearchInputChange}
                value={searchTerm}
              />
            </form>
          </div>
          <div className={styles.sellUsed}>
            <button className={styles.sellUsedItem} onClick={handleManageInventory}>
              Sell Used Items
            </button>
          </div>
        </div>
      </div>
      <div className={styles.searchInputphone}>
        <form className={`d-flex ${styles.search}`} onSubmit={(e) => e.preventDefault()}>
          <input
            className={`form-control text-custom me-2 w-30 ${styles.textCustom}`}
            type="search"
            placeholder="Search"
            aria-label="Search"
            onChange={handleSearchInputChange}
            value={searchTerm}
          />
        </form>
      </div>
      <div className={styles.productsGrid}>
        {allWishlist && allWishlist.length > 0 ? (
          allWishlist.map((product) => {
            const discountPercentage = product.pricing && product.discountedPrice
              ? Math.round(((product.pricing - product.discountedPrice) / product.pricing) * 100)
              : 0;

            return (
              <div key={product.id} className={styles.productCard}>
                <span className={styles.edit} onClick={() => handleRemoveWishlist(product.productId)}>X</span>
                <img
                  src={product.images.split(',')[0]}
                  alt={product.productName}
                  className={styles.productImage}
                />
                <h3 className={styles.productName}>{product.productName}</h3>
                <div className={styles.priceContainer}>
                  {product.discountedPrice > 0 && <span className={styles.originalPrice}>₹{product.pricing}</span>}
                  <span className={styles.discountedPrice}>₹{product.discountedPrice}</span>
                  <span className={styles.discount}>
                    {discountPercentage > 0 ? `${discountPercentage}% OFF` : "No Discount"}
                  </span>
                </div>
                <div className={styles.ratingContainer}>
                  <span className={styles.star}>★</span>
                  <span className={styles.rating}>{product.averageRating}</span>
                </div>
                <button
                  className={styles.viewMore}
                  onClick={() => navigate(`/ecominventorydetail`, { state: { productId: product.id } })}
                >
                  View Detail
                </button>
              </div>
            );
          })
        ) : (
          <div className={styles.noProducts}>No products available</div>
        )}
      </div>
    </div>
  );
}

export default Wishlist;
