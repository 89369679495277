import React, { useEffect, useState } from "react";
import styles from "./profile.module.css";
import { useSelector } from "react-redux";
import { fetchProfile } from "../../api/login";
import phone from '../../Assest/img/pphone.svg';
import email from '../../Assest/img/pemail.svg';
import gst from '../../Assest/img/pgst.svg';
import pan from '../../Assest/img/ppan.svg';
import businessadd from '../../Assest/img/pbusinessbuilding.svg';
import closeYear from '../../Assest/img/closeyear.svg';
import dataTally from '../../Assest/img/dataTally.svg';
import TermCondition from '../../Assest/img/T&C.svg';
import pdelete from '../../Assest/img/pdelete.svg';
import website from '../../Assest/img/website.svg';
import { useNavigate } from "react-router";





function Profile() {
  const navigate= useNavigate()
  const token = useSelector((state) => state.login.token);
  const [profileData, setProfileData] = useState({
   
  });

  useEffect(() => {
    const getProfile = async () => {
      const result = await fetchProfile(token);
      setProfileData(result.data);
      console.log(result.data);
    };
    getProfile();
  }, [token]);


  const editProfile = () => {
  navigate('/profile/editProfile')  
  }

  return (
    <div className={styles.container}>
      <div className={styles.personInfo}>
        <div className={styles.nameLogo}>
          <img src={profileData.logo}></img>
          <div>

            <h5>{profileData.storeName}</h5>
            <p>owner name - {profileData.name}</p>
          </div>
        </div>
        <div className={styles.phone}>
          <img src={phone}></img>
          <p>Business Phone Number - {profileData.phone}</p>
        </div>
        <div className={styles.email}>
          <img src={email}></img>
          <p>Business Email - {profileData.email}</p>
        </div>
        <div className={styles.type}>
          <img src={dataTally}></img>
          <p>Current Plan -{profileData.planName}</p>
        </div>
      </div>
      <div className={styles.info}>
        <div className={styles.type}>
          <img src={gst}></img>
          <p>GST Number - {profileData.gst}</p>
        </div>
        <div className={styles.type}>
          <img src={pan}></img>
          <p>PAN Number - { profileData.pinNumber}</p>
        </div>
        <div className={styles.type}>
          <img src={businessadd}></img>
          <p>Business Address - {profileData.address}</p>
        </div>
       
      </div>
      <div className={styles.business}>
        <div className={styles.logo}>
          <img src={profileData.logo}></img>
        </div>
        <div className={styles.fin}>
          <p className={styles.buisnessType}>{profileData.vendorCategoryName}</p>
          <p>Business Address - {profileData.address}</p>
        </div>
      </div>
      <div className={styles.financial}>
        <img src={closeYear}></img>

        <p>Close Financial Year</p>
      </div>
      <div className={styles.info}>
        <div className={styles.type}>
          <img src={dataTally}></img>
          <p>Data Export To Tally</p>
        </div>
        <div className={styles.type}>
          <img src={TermCondition}></img>
          <p>Term and Condition </p>
        </div>
        <div className={styles.type}>
          <img src={pdelete}></img>
          <p>Delete Account</p>
        </div>
      </div>

      <div className={styles.info}>
        <div className={styles.type}>
          <img src={website}></img>
          <p>Website</p>
        </div>
        <div className={styles.type}>
          <img src={website}></img>
          <p>Social Sites</p>
        </div>
      </div>
      <div className={styles.signature}>
        <p>Signature</p>
        <img src={profileData.signature}></img>
      </div>
      <button className={styles.btn} onClick={editProfile}>Edit</button>
    </div>
  );
}

export default Profile;
