import React from "react";
import Layout from "../Layout/Layout";
import Category from "../../Ecom/Category";

function CategoryFlow() {
  return (
    <Layout child="StoreDetail">
      <Category />
    </Layout>
  );
}

export default CategoryFlow;
