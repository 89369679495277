import React, { useEffect, useState } from 'react';
import Back from '../../Assest/img/btn-back.svg';
import additem from '../../Assest/img/additem.svg';
import catalog from '../../Assest/img/catalog.svg';
import inventoryImg from '../../Assest/img/inventory-img.svg';
import styles from './inventoryDetail.module.css'; // Importing CSS module correctly
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router';
import { inventoryList } from '../../api/inventoryAPI';
import { useSelector } from 'react-redux';
import defaultImg from '../../Assest/img/defaultImg.svg'

function InventoryDetail() {
  const [cardData, setCardData] = useState([]);
  const [searchTerm, setSearchTerm] = useState(''); // State for search input

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const categoryId = queryParams.get('category');
  const token = useSelector(state => state.login.token);

  // Fetch inventory list from API
  const fetchInventoryList = async (search = '') => {
    try {
      console.log(categoryId);
      const response = await inventoryList(token, categoryId, search);
      if (response.message === 'success') {
        setCardData(response.data.inventoryList);
        console.log(response.data.inventoryList);
      }
    } catch (error) {
      console.error('Failed to fetch category list:', error);
    }
  };

  // Initial data fetch
  useEffect(() => {
    fetchInventoryList();
  }, [categoryId]);

  // Handle search input change
  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Handle form submission for search
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    fetchInventoryList(searchTerm); // Fetch the list with the search term
  };

  const backToCategory = () => {
    navigate('/category');
  };

  const viewSingleInventory = (inventoryId) => {
    console.log(inventoryId);
    navigate(`/singleinventory?inventoryId=${inventoryId}`);
  };

  const navigateToAddInventory = () => {
    navigate('/addinventory');
  };

  return (
    <div className={styles['inventoryDetail-container']}>
      <div className={styles['inventory-top-tab']}>
        <form className={`d-flex ${styles.search}`} onSubmit={handleSearchSubmit}>
          <input
            className={`form-control ${styles['text-custom']} me-2 w-15`}
            type="search"
            placeholder="Search"
            aria-label="Search"
            value={searchTerm} // Controlled input for search
            onChange={handleSearchInputChange} // Update search term on change
          />
          <button className={`btn ${styles['btn-custom']}`} type="submit">
            Search
          </button>
        </form>
        <div className={styles['inventory-btn']}>
          <button className={styles.additem} onClick={navigateToAddInventory}>
            Add item
          </button>
          <button className={styles.catalog}>
            Catalog
          </button>
        </div>
      </div>

      <div className={styles['inventory-card-container']}>
        {cardData.map((e, index) => (
          <div key={index} className={`card-body ${styles.box}`}>
            <img src={e.img ? e.img : defaultImg} className={`${styles['card-img-top']}`} alt="Necklace" />
            <div className={styles['card-detail']}>
              <h5 className={styles['card-title']}>{e.name}</h5>
              <p>id: <span>{e.uniqueId}</span></p>
              <p>Price: <span>{e.dayRent}</span></p>
              <p>Quantity: <span>{e.totalQuantity}</span></p>
              <button
                className={`btn ${styles['btn-view-more']}`}
                onClick={() => viewSingleInventory(e.inventoryId)}
              >
                View Detail
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default InventoryDetail;
