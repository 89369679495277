import React, { useMemo, useState, useEffect } from "react";
import styles from "./createOrder.module.css";
import "react-datepicker/dist/react-datepicker.css";
import { useTable, usePagination } from "react-table";
import { Column } from "./column";
import save from "../../Assest/img/save.svg";
import cancel from "../../Assest/img/cancel.svg";
import OrderStatusComplete from "./OrderStatusCompleteModal";
import OrderStatusInComplete from './OrderStatusIncompleteModal';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { createOrder } from "../../api/order";
import { useDispatch } from "react-redux";
import { creteOrderAction } from "../../Reduxstore/createOrder";
import { toast } from "react-toastify";
import moment from "moment-timezone";

function CreateOrder() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const partyName = useSelector((state) => state.order.partyName);
  const partyId = useSelector((state) => state.order.partyId);
  const startDate = useSelector((state) => state.order.startDate);
  const endDate = useSelector((state) => state.order.endDate);
  let orderItems = useSelector((state) => state.order.addItem);
  const token = useSelector((state) => state.login.token);

  const [Quotation, setQuotation] = useState(0);
  const [isOrderStatusModalOpen, setOrderStatusModalOpen] = useState(false);
  const [isOrderStatusIncompleteModalOpen, setOrderStatusIncompleteModalOpen] = useState(false);

  const [gstEnabled, setGstEnabled] = useState(false); // For controlling GST input
  const [totalPrice, setTotalPrice] = useState(0);
  const [advance, setAdvance] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [gst, setGst] = useState(null);
  const [gstValue, setGstValue] = useState(null);
  const [subTotal, setSubTotal] = useState(null);
  const [shouldRedirect, setShouldRedirect] = useState(false);


  const [isLoading, setIsLoading] = useState(false);

  const inventoryItems = orderItems.map(({ id, quantity, godownId }) => ({
    inventoryId: id,
    quantity,
    godownId,
  }));

  useEffect(() => {
    // Redirect to "/order" after successfully creating an order
    if (shouldRedirect) {
      navigate("/order");
    }
  }, [shouldRedirect, navigate]);
  
  


  const handleDeleteInventory = (row) => {

    const id = row.original.id
    const matchingId = orderItems.find(e=>e.id === id)
    console.log(matchingId)
    if (matchingId) {
      const updateData = orderItems.filter(e => e !== matchingId)
      orderItems = updateData;
    dispatch(creteOrderAction.addItem(updateData));
    }
  }

  const memoizedColumns = useMemo(() => Column(handleDeleteInventory), []);

  const memoizedData = useMemo(() => orderItems, [orderItems]);

  const toggleSwitch = () => {
    setQuotation(1);
  };

  const start = new Date(startDate ? startDate : '');
  const end = new Date(endDate ? endDate : '');
  
  // Format the dates using Moment.js
  const formattedStartDate = moment(start).format('YYYY-MM-DD');
  const formattedEndDate = moment(end).format('YYYY-MM-DD');
  
  console.log(formattedStartDate, formattedEndDate);
  
  // Calculate the difference in milliseconds
  const differenceInTime = end.getTime() - start.getTime();

  // Convert the difference from milliseconds to days
  const differenceInDays = differenceInTime / (1000 * 3600 * 24)+1;

  const handleCloseOrderStatus = () => {
    setOrderStatusModalOpen(false);
  };

  const handleCloseIncompleteOrderStatus = () => {
    setOrderStatusIncompleteModalOpen(false);
  };
  const handleCreateOrder = async () => {
    setIsLoading(true);
    try {
      const result = await createOrder(
        token,
        formattedStartDate,
        formattedEndDate,
        partyId,
        gstValue,
        discount,
        advance,
        totalPrice,
        Quotation,
        inventoryItems
      );
      
      if (result.message === 'success') {
        setShouldRedirect(true); 
        setOrderStatusModalOpen(true);
        
        // Use setTimeout to delay clearing items and navigation
        setTimeout(() => {
          // Clear the items from Redux
          dispatch(creteOrderAction.addItem([]));
          // Navigate to orders page
          navigate("/order");
        }, 1000); // Delay of 1 second
      } else {
        toast.error(result.message || "Failed to create order");
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Error creating order");
    } finally {
      setIsLoading(false);
    }
  };

  // const handleGstCheckboxChange = () => {
  //   setGstEnabled(!gstEnabled);
  // };

  



  const handleChangeDiscount = (event) => {
    const value = event.target.value;
    setDiscount(value);
  };

  const handleChangeAdvance = (event) => {
    const value = event.target.value;
    setAdvance(value);
  };

  const handleChangeGst = (event) => {
    const value = event.target.value;
    console.log(value);
    setGst(value ? Number(value) : 0);
  };



  useEffect(() => {

    if (!partyName || !startDate || !endDate ||  (orderItems && !orderItems.length)) {
      if (!shouldRedirect) {
      return navigate("/order/selectDate");
      }
    }
   
    const price = memoizedData.map((e) => e.price * e.quantity);
    const addPrice = price.reduce((e, i) => e + i);
    setSubTotal(addPrice * differenceInDays);
    console.log(subTotal);

    const afterDiscount = subTotal - +discount;
    // Convert gst to a number using the unary + operator
    let gstValue = +gst; // This ensures gst is treated as a number, not a string

    let g;
    if (!isNaN(gstValue) && gstValue >= 0) {
      g = (afterDiscount * gstValue) / 100;
      setGstValue(Math.round(g));
    } else {
      g = 0;
    }
    let num = afterDiscount - +advance + g;

    setTotalPrice(Math.round(num));

    console.log(gst, g, num);
  }, [memoizedData, advance, discount, gst, differenceInDays, subTotal]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,

    state: { pageIndex },
  } = useTable(
    {
      columns: memoizedColumns,
      data: memoizedData,
      initialState: { pageIndex: 0, pageSize: 9 },
    },
    usePagination
    );
  
  const addMoreInventory = () => {
    navigate('/order/additem' , { state: { savedData: true } })
  }

  return (
    <div className={styles.container}>
      <div className={styles.cardContainer}>
        <div className={styles.head}>
          <p>Create Order</p>

          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              onClick={toggleSwitch}
            />
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckDefault"
            >
              Quotation
            </label>
          </div>
        </div>
        <div className={styles.info}>
          <div>
            <label>Party Name</label>
            <input value={partyName} />
          </div>
          <div className={styles.date}>
            <div>
              <label>Start Date</label>
              <input value={startDate} />
            </div>

            <div>
              <label>End Date</label>
              <input value={endDate} />
            </div>
          </div>
        </div>
        <div className={styles.select}>
          <h1>Selected Item List</h1>

          <div className={styles.table}>
            <table
              {...getTableProps()}
              className="table "
              id="customers"
              key="customers"
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    key={headerGroup.id}
                  >
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()} key={column.id}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.length > 0 ? (
                  page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} key={row.id}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()} key={cell.column.id}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={memoizedColumns.length}>No Data Available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className={styles.subTotal}>
          <p>SubTotal {subTotal}</p>
        </div>
        <div className={styles.additem} onClick={addMoreInventory}>
          <button>Add More Inventory</button>
        </div>

        <div className={styles.paymentInfo}>
          <div>
            <label>GST</label>
            {/* <div className={styles.gst}>
              <input
                type="checkbox"
                id="gstCheckbox"
                onChange={handleGstCheckboxChange}
              />
              <input
                type="number"
                value={gst}
                name="gst"
                onChange={handleChangeGst}
                disabled={!gstEnabled}
                placeholder={gstEnabled ? "Enter GST No." : " "}
                style={{ width: "7rem" }}
              />
            </div> */}
             <input
              type="number"
              value={gst}
              name="gst"
              onChange={handleChangeGst}
            ></input>
          </div>
          <div>
            <label>Discount</label>
            <input
              type="number"
              value={discount}
              name="discount"
              onChange={handleChangeDiscount}
            ></input>
          </div>
          <div>
            <label>Advance</label>
            <input
              type="number"
              value={advance}
              name="advance"
              onChange={handleChangeAdvance}
            />
          </div>
          <div>
            <label>Total Price</label>
            <input value={totalPrice}></input>
          </div>
        </div>
        <div className={styles.saveCancel}>
          
          <button className={styles.save} onClick={handleCreateOrder}>
            Create Order
          </button>
        </div>
      </div>
      <OrderStatusComplete
        isOpen={isOrderStatusModalOpen}
        onClose={handleCloseOrderStatus}
      ></OrderStatusComplete>
      <OrderStatusInComplete
      isOpen={isOrderStatusIncompleteModalOpen}
      onClose={handleCloseIncompleteOrderStatus}>
      </OrderStatusInComplete>
    </div>
  );
}

export default CreateOrder;
