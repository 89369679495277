import React, { useEffect, useState } from "react";
import styles from "./addParty.module.css";
import Back from "../../Assest/img/btn-back.svg";
import save from "../../Assest/img/save.svg";
import cancel from "../../Assest/img/cancel.svg";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { addParty } from "../../api/party"; // Ensure this function is imported

function AddParty() {
  const navigate = useNavigate();
  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    state: "",
    pincode: "",
    gst: "",
    city: "",
    panNumber: "",
    doc: "",
    profileImg: "",
  });
  const token = useSelector((state) => state.login.token);

  const isFormValid = () => {
    return (
      data.name.trim() !== "" &&
      data.phone.trim() !== "" 
    )
     
  };

  const handleAddParty = async (e) => {
    e.preventDefault();
    try {
      const result = await addParty(token, data);
      console.log(result);
      if (result.message === 'success') {
        navigate("/party");
      }
    } catch (error) {
      console.error("Failed to add party:", error);
    }
  };

  const back = () => {
    navigate("/party");
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            setData((prevData) => ({
                ...prevData,
                profileImg: file 
            }));
        };
        reader.readAsDataURL(file);
    }
  };
  const displayImage = () => {
    if (data.img instanceof File) {
      return URL.createObjectURL(data.profileImg);
    } else if (data.profileImg) {
      return data.profileImg; // If img is a string (e.g., URL from the backend)
    } else {
      return ""; 
    }
  };
console.log(data.profileImg)
  return (
    <div className={styles.container}>
     
      <div className={styles.top}>
        <p>Add Party</p>
      </div>
      <div className={styles.CardContainer}>
        <div className={styles.imageDiv}>
        <img src={displayImage()} alt="profile"  className={styles.image} />
          <input type="file" onChange={handleImageChange} placeholder="Update Image" />
        </div>
        <div className={styles.box}>
          <div className={styles.text}>Create Party</div>
          <div className={styles.info}>
            <div className={styles.one}>
              <label>Party Name</label>
              <input name="name" value={data.name} onChange={handleChange} placeholder="Enter party name" required/>
            </div>
            <div className={styles.two}>
              <label>Contact No.</label>
              <input name="phone" value={data.phone} onChange={handleChange} placeholder="Enter contact number"  required/>
            </div>
            <div className={styles.two}>
              <label>Email</label>
              <input name="email" value={data.email} onChange={handleChange} placeholder="Enter email" />
            </div>
          </div>
        </div>
        <div className={styles.box}>
          <div className={styles.text}>Business Detail</div>
          <div className={styles.info}>
            <div className={styles.one}>
              <label>GST No.</label>
              <input name="gst" value={data.gst} onChange={handleChange} placeholder="Enter GST number" />
            </div>
            <div className={styles.two}>
              <label>PAN No.</label>
              <input name="panNumber" value={data.panNumber} onChange={handleChange} placeholder="Enter PAN number" />
            </div>
          </div>
        </div>
        <div className={styles.box}>
          <div className={styles.text}>Address Details</div>
          <div className={styles.info}>
            <div className={styles.one}>
              <label>Address</label>
              <input name="address" value={data.address} onChange={handleChange} placeholder="Enter address" />
            </div>
            <div className={styles.two}>
              <label>State</label>
              <input name="state" value={data.state} onChange={handleChange} placeholder="Enter state" />
            </div>
            <div className={styles.two}>
              <label>City</label>
              <input name="city" value={data.city} onChange={handleChange} placeholder="Enter city" />
            </div>
            <div className={styles.two}>
              <label>Pin Code</label>
              <input name="pincode" value={data.pincode} onChange={handleChange} placeholder="Enter pin code" />
            </div>
          </div>
        </div>
        <div className={styles.saveCancel}>
          <button 
            className={`${styles.save} ${!isFormValid() ? styles.disabled : ''}`}
            onClick={(e) => {
              e.stopPropagation();
              if (isFormValid()) {
                handleAddParty(e);
              }
            }}
            type="button"
            disabled={!isFormValid()}
          >
            <img src={save} alt="Save" /> Create Party
          </button>
          <button className={styles.cancel} onClick={back}>
            <img src={cancel} alt="Cancel" /> Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddParty;
