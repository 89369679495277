import React from 'react';
import DetailInventory from '../../Ecom/DetailInventory';
import Layout from "../Layout/Layout";

function DetailInventoryFlow() {
    return (
        <Layout child="Product Details">
            <DetailInventory />
        </Layout>
    );
}

export default DetailInventoryFlow;
