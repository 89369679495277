import React, { useState, useEffect } from "react";
import additem from "../../Assest/img/additem.svg";
import styles from "./party.module.css";
import inventoryImg from "../../Assest/img/inventory-img.svg";
import call from "../../Assest/img/call.svg";
import addParty from "../../Assest/img/addParty.svg";
import DetailPartyModal from "./DetailPartyModal";
import EditParty from "./EditParty";
import { useNavigate } from "react-router";
import { allParty } from "../../api/party";
import { useSelector } from "react-redux";
import defaultImg from '../../Assest/img/defaultImg.svg'

function Party() {
  const navigate = useNavigate();
  const token = useSelector((state) => state.login.token);

  const [cardData, setCardData] = useState([]);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedPartyId, setSelectedPartyId] = useState(null); // State to store the selected partyId
  const [searchTerm, setSearchTerm] = useState(''); 

  const fetchPartyList = async (search='') => {
    try {
      const response = await allParty(token, search);
      if (response.message === "success") {
        setCardData(response.data.partiesList);
        console.log(response.data.partiesList);
      }
    } catch (error) {
      console.error("Failed to fetch category list:", error);
    }
  };

  useEffect(() => {
    fetchPartyList();
  }, [token,searchTerm]); // Ensure this only runs once after mounting

  const openDetailModal = (partyId) => {
    setSelectedPartyId(partyId); // Store the selected partyId in state
    setIsDetailModalOpen(true);
    setIsEditModalOpen(false); // Ensure Edit modal is closed
  };

  const closeDetailModal = () => setIsDetailModalOpen(false);

  const openEditModal = () => {
    setIsDetailModalOpen(false); // Close Detail modal
    setIsEditModalOpen(true); // Open Edit modal
  };

  const closeEditModal = () => setIsEditModalOpen(false);

  const openAddParty = () => {
    navigate("/addparty");
  };

  const updateVendorData = async () => {
    const newVendorData = await allParty(token)
    console.log(newVendorData.data.partiesList)
    setCardData(newVendorData.data.partiesList);
  }

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value); 
  };

  // Handle search form submission
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    fetchPartyList(searchTerm); // Fetch data based on the search term
  };

  return (
    <div className={styles.container}>
      
      <div className={styles.partyTopTab}>
        <form className={`d-flex search`}  onSubmit={handleSearchSubmit}>
          <input
            className={`form-control text-custom me-2 w-30 ${styles.textCustom}`}
            type="search"
            placeholder="Search"
            aria-label="Search"
            onChange={handleSearchInputChange}
          />
          <button className={`btn ${styles.btnCustom}`} type="submit">
            Search
          </button>
        </form>
        <div className={styles.addBtn}>
          {/* This button should be outside the map to not repeat */}
          <button className={styles.addparty} onClick={openAddParty}>
            <img src={addParty} alt="Add Party" />
            Add Party
          </button>
        </div>
      </div>

      <div className={styles.partyCardContainer}>
        {cardData.map((e, index) => (
          <div key={index} className={`card-body ${styles.box}`}>
            <img src={e.profileImg ? e.profileImg : defaultImg} className={styles.cardImgTop} alt="..." />
            <h5 className={styles.title}>{e.name}</h5>
            <h5 className={styles.email}>{e.email}</h5>
            <div className={styles.infoDiv}>
              <a
                href="#"
                className={`btn ${styles.btnView}`}
                onClick={() => openDetailModal(e.userId)}
              >
                View More
              </a>
              <button>
                <img src={addParty} alt="Add" />
              </button>
              <button>
                <img src={call} alt="Call" />
              </button>
            </div>
          </div>
        ))}
      </div>

      {isDetailModalOpen && (
        <DetailPartyModal
          isOpen={isDetailModalOpen}
          onClose={closeDetailModal}
          openEditModal={openEditModal}
          partyId={selectedPartyId}
        />
      )}
      {isEditModalOpen && (
        <EditParty
          openEditModal={isEditModalOpen}
          closeEditModal={closeEditModal}
          partyId={selectedPartyId}
          updateProfile={updateVendorData}
        />
      )}
    </div>
  );
}

export default Party;
