import { createSlice } from "@reduxjs/toolkit";


const token= localStorage.getItem('token')
const initialState = {
  login: token? true :false,
  token: token ? token : null,
  userProfile: {},
  plan:0

};

const LoginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    login: (state) => {
      state.login = true;
    },
    token: (state, action) => {
      state.token = action.payload;
    },
    userProfile: (state, action) => {
      state.userProfile = action.payload
    },
    logOut: (state) => {
      state.login = false
    },
    plan: (state, action) => {
      state.plan = action.payload
    }
   
  },
});

export const loginAction = LoginSlice.actions;
export default LoginSlice;

