import React, { useEffect, useState } from "react";
import styles from "./orderStatus.module.css";
import addDamageItem from "../../Assest/img/addDamageItem.svg";
import defaultImg from "../../Assest/img/defaultImg.svg";
import { useSelector } from "react-redux";
import { damageItem, orderDetails } from "../../api/order";

function DamageOrder({ isOpen, onClose, orderId, updateData }) {
  const token = useSelector((state) => state.login.token);

  const [data, setData] = useState([]);
  const [damageCart, setDamageCart] = useState([]);

  useEffect(() => {
    if (isOpen) {
      const fetchOrderDetails = async () => {
        try {
          const response = await orderDetails(token, orderId);
          setData(response.data.cartItems || []);
          console.log(response)
        } catch (error) {
          console.error("Failed to fetch order details:", error);
          setData([]);
        }
      };
      fetchOrderDetails();
    }
  }, [token, orderId, isOpen]);

  const handleSubmitDamageItem = async () => {
    if (damageCart.length > 0) {
      await damageItem(token, orderId, damageCart);
      updateData();
        onClose();
        setDamageCart([])
    } else {
      alert("Please complete all fields before saving.");
    }
  };
  const handleChange = (event, cartItemId) => {
    const { name, value } = event.target;

    setDamageCart((prevDamageCart) => {
      // Find the index of the item with the given cartItemId
      const itemIndex = prevDamageCart.findIndex(
        (item) => item.cartItemId === cartItemId
      );
      console.log(itemIndex);
      if (itemIndex >= 0) {
        // Update existing item
        const updatedCart = [...prevDamageCart];
        updatedCart[itemIndex] = {
          ...updatedCart[itemIndex],
          [name]: value,
        };
        return updatedCart;
      } else {
        return [...prevDamageCart, { [name]: value, cartItemId }];
      }
    });
  };

  console.log(damageCart);
  const totalPrice = data.reduce((acc, e) => acc + e.totalAmount, 0);

  return isOpen ? (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div
        className={styles.modalContent2}
        onClick={(e) => e.stopPropagation()}
      >
        <p className={styles.head}>Damage Items</p>
        <div className={styles.container1}>
          {data.map((e, index) => (
            <div key={index} className={`card-body ${styles.box}`}>
              <img
                className={styles.image}
                src={e.img ? e.img : defaultImg}
                alt="Item"
              />
              <p className={styles.head}>{e.inventoryName}</p>
              <p>Available Quantity : {e.quantity}</p>

              <input
                className={styles.input}
                type="number"
                placeholder="Damage Price"
                name="damageAmount"
                value={damageCart.damageAmount}
                onChange={(event) => handleChange(event, e.cartItemId)}
              />
              <div className={styles.addDamageQuantity}>
                <input
                  type="number"
                  onChange={(event) => handleChange(event, e.cartItemId)}
                  name="damageQuantity"
                  value={damageCart.damageQuantity}
                />
              </div>
            </div>
          ))}
        </div>
        <p className={styles.price}> Total Price : {totalPrice} </p>
        <div className={styles.saveCancel}>
          <button className={styles.close} onClick={onClose}>Cancel</button>

          <button className={styles.save} onClick={handleSubmitDamageItem}>
            Submit
          </button>
        </div>
      </div>
    </div>
  ) : null;
}

export default DamageOrder;
