import React ,{useState, useEffect} from 'react'
import styles from './home.module.css';
import homeGraph from '../../Assest/img/homeGraph.svg';
import homeUp from '../../Assest/img/homeUp.svg';
import homeRun from '../../Assest/img/homeRun.svg';
import homeWeb from '../../Assest/img/homeWeb.svg';
import homeEcom from '../../Assest/img/homeEcom.svg';
import workReturn from '../../Assest/img/workReturn.svg'
import workDelivery from '../../Assest/img/workDelivery.svg'
import { Link } from 'react-router-dom'
import rajadv from '../../Assest/img/raj adv.svg';
import { useSelector } from 'react-redux';
import { homePage } from '../../api/login';
import { useNavigate } from 'react-router-dom';

function Home() {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const token = useSelector(state => state.login.token);
  
  useEffect(() => {
    const fetchData = async () => {
      const response = await homePage(token);
      setData(response.orderResponse);
    };
    fetchData();
  }, []);
  
  return (
     <div className={styles.container}>

      <div className={styles.cardContainer}>
        <div className={styles.home}>

          <div className={styles.box1} onClick={()=>{
              navigate('/order?type=upcoming')
            }}>
            <div className={styles.up}>
              <img src={homeUp}></img>
              <img src={homeGraph}></img>
            </div>
            <div className={styles.down}>
              <p>{data.upcomingOrders}</p>
              <p>Upcoming</p>
            </div>
          </div>

          <div className={styles.box2}  onClick={()=>{
              navigate('/order?type=ongoing')
            }}>
            <div className={styles.up}>
              <img src={homeRun}></img>
              <img src={homeGraph}></img>
            </div>
            <div className={styles.down}>
              <p>{data.runningOrders}</p>
              <p>Running</p>
            </div>
          </div>

          <div className={styles.box3}>
            <div className={styles.up}>
              <img src={homeWeb}></img>
              <img src={homeGraph}></img>
            </div>
            <div className={styles.down}>
              <p>{data.tendersCount}</p>
              <p>Tenders</p>
            </div>
          </div>

          <div className={styles.box4}  onClick={() => navigate('/ecom')}>
            <div className={styles.up}>
              <img src={homeEcom}></img>
              <img src={homeGraph}></img>
            </div>
            <div className={styles.down}>
              <p>&nbsp;</p>
              <p>E-com</p>
            </div>
          </div>
        </div>
      {data.adsBanners && data.adsBanners.map(add=>(<div className={styles.work}>
        <img className={styles.add} src={add.img} />
        
    
          </div>))}

        </div>
          </div>
  )
}

export default Home


