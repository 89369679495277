import { baseURL } from '../config';
import axios from 'axios';
import { toast } from 'react-toastify';



export async function getCategoryList(token , search) {
    try {
       
// Construct URL based on whether search exists
const url = search 
? `${baseURL}inventories/categories?searchKey=${search}`
: `${baseURL}inventories/categories`;

   const response = await axios.get(url,{
    headers: {
      Authorization: token,
    } 
   })
        if (response && response.status === 200 && response.data.message === 'success') {
            return response.data;
        }else {
            toast.error(`Unexpected response: ${response.data.errorMessage}`);
         
          }
    }  catch (error) {
      // Handle axios errors
      if (error.response) {
          // Server responded with error status
          const errorMsg = error.response.data.errorMessage || error.response.data.message || 'Server error';
          toast.error(errorMsg);
          throw new Error(errorMsg);
      } else if (error.request) {
          // Request made but no response
          toast.error('No response from server');
          throw new Error('No response from server');
      } else {
          // Other errors
          toast.error(error.message || 'Request failed');
          throw error;
      }
  }
}

export async function inventoryList(token,categoryId,search='') {
  try {
     

 const response = await axios.get(`${baseURL}inventories/list-inventory?draw=20&page=0&categoryId=${categoryId}&searchKey=${search}`,{
  headers: {
    Authorization: token,
  } 
 })
      if (response && response.status === 200 && response.data.message === 'success') {
          return response.data;
      }else {
          toast.error(`Unexpected response: ${response.data.errorMessage}`);
          console.error("Unexpected response:", response ,response.data.errorMessage);
       
        }
  }  catch (error) {
    // Handle axios errors
    if (error.response) {
        // Server responded with error status
        const errorMsg = error.response.data.errorMessage || error.response.data.message || 'Server error';
        toast.error(errorMsg);
        throw new Error(errorMsg);
    } else if (error.request) {
        // Request made but no response
        toast.error('No response from server');
        throw new Error('No response from server');
    } else {
        // Other errors
        toast.error(error.message || 'Request failed');
        throw error;
    }
}
}

export async function inventoryDetail(token,inventoryId) {
  try {
     
 const response = await axios.get(`${baseURL}inventories/details/${inventoryId}`,{
  headers: {
    Authorization: token,
  } 
 })
      if (response && response.status === 200 && response.data.message === 'success') {
          return response.data;
      }else {
          toast.error(`Unexpected response: ${response.data.errorMessage}`);
          console.error("Unexpected response:", response ,response.data.errorMessage);
       
        }
  }  catch (error) {
    // Handle axios errors
    if (error.response) {
        // Server responded with error status
        const errorMsg = error.response.data.errorMessage || error.response.data.message || 'Server error';
        toast.error(errorMsg);
        throw new Error(errorMsg);
    } else if (error.request) {
        // Request made but no response
        toast.error('No response from server');
        throw new Error('No response from server');
    } else {
        // Other errors
        toast.error(error.message || 'Request failed');
        throw error;
    }
}
}

export async function addInventory(token ,Data) {
  try {
      const formData = new FormData();
      for (const key in Data) {
          if (Data[key] instanceof File) {
              formData.append(key, Data[key], Data[key].name);
          } else {
              formData.append(key, Data[key]);
          }
      }

      formData.forEach((value, key) => {
      });


      const response = await axios.post(`${baseURL}inventories/add-inventory`,formData,{
      headers: {
        Authorization: token,
      }
    })
    
    if (
      response &&
      (response.status === 200) & (response.data.message === "success")
    ) {
      return response.data;
    }else {
      toast.error(`Unexpected response: ${response.data.errorMessage}`);
      console.error("Unexpected response:", response ,response.data.errorMessage);
   
    }
  }  catch (error) {
    // Handle axios errors
    if (error.response) {
        // Server responded with error status
        const errorMsg = error.response.data.errorMessage || error.response.data.message || 'Server error';
        toast.error(errorMsg);
        throw new Error(errorMsg);
    } else if (error.request) {
        // Request made but no response
        toast.error('No response from server');
        throw new Error('No response from server');
    } else {
        // Other errors
        toast.error(error.message || 'Request failed');
        throw error;
    }
}
}

export async function editInventory(token ,Data,inventoryId) {
  try {
      const formData = new FormData();
      for (const key in Data) {
          if (Data[key] instanceof File) {
              formData.append(key, Data[key], Data[key].name);
          } else {
              formData.append(key, Data[key]);
          }
      }

   


      const response = await axios.post(`${baseURL}inventories/edit-inventory/${inventoryId}`,formData,{
      headers: {
        Authorization: token,
      }
    })
    
    if (
      response &&
      (response.status === 200) & (response.data.message === "success")
    ) {
      return response.data;
    }else {
      toast.error(`Unexpected response: ${response.data.errorMessage}`);
      console.error("Unexpected response:", response ,response.data.errorMessage);
   
    }
  }  catch (error) {
    // Handle axios errors
    if (error.response) {
        // Server responded with error status
        const errorMsg = error.response.data.errorMessage || error.response.data.message || 'Server error';
        toast.error(errorMsg);
        throw new Error(errorMsg);
    } else if (error.request) {
        // Request made but no response
        toast.error('No response from server');
        throw new Error('No response from server');
    } else {
        // Other errors
        toast.error(error.message || 'Request failed');
        throw error;
    }
}
}

export async function godownList(token) {
  try {
     
 const response = await axios.get(`${baseURL}inventories/godown`,{
  headers: {
    Authorization: token,
  } 
 })
      if (response && response.status === 200 && response.data.message === 'success') {
          return response.data;
      }else {
          toast.error(`Unexpected response: ${response.data.errorMessage}`);
          console.error("Unexpected response:", response ,response.data.errorMessage);
       
        }
  }  catch (error) {
    // Handle axios errors
    if (error.response) {
        // Server responded with error status
        const errorMsg = error.response.data.errorMessage || error.response.data.message || 'Server error';
        toast.error(errorMsg);
        throw new Error(errorMsg);
    } else if (error.request) {
        // Request made but no response
        toast.error('No response from server');
        throw new Error('No response from server');
    } else {
        // Other errors
        toast.error(error.message || 'Request failed');
        throw error;
    }
}
}


export async function addGodown(token,name,address) {
  try {
    

    const response = await axios.post(`${baseURL}inventories/add-godown`, {
      name,address
    },{
      headers: {
        Authorization: token,
      }
    })
    
    if (
      response &&
      (response.status === 200) & (response.data.message === "success")
    ) {
      return response.data;
    }else {
      toast.error(`Unexpected response: ${response.data.errorMessage}`);
      console.error("Unexpected response:", response ,response.data.errorMessage);
   
    }
  }  catch (error) {
    // Handle axios errors
    if (error.response) {
        // Server responded with error status
        const errorMsg = error.response.data.errorMessage || error.response.data.message || 'Server error';
        toast.error(errorMsg);
        throw new Error(errorMsg);
    } else if (error.request) {
        // Request made but no response
        toast.error('No response from server');
        throw new Error('No response from server');
    } else {
        // Other errors
        toast.error(error.message || 'Request failed');
        throw error;
    }
}
}

function base64ToBlob(base64String, mimeType) {
  const byteCharacters = atob(base64String.split(',')[1]);  // Decode base64 to binary
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: mimeType });
}


export async function addCategory(token, Data) {
  try {
    // Validate inputs
    if (!token) {
      throw new Error('Token is required');
    }
    
    if (!Data || typeof Data !== 'object') {
      throw new Error('Invalid data provided');
    }

    const formData = new FormData();
    
    // Check if name exists and is a string before adding
    if (Data.name && typeof Data.name === 'string') {
      formData.append('name', Data.name.trim());
    } else {
      throw new Error('Category name is required');
    }
    
    // Check if img exists and is a File before adding
    if (Data.img && Data.img instanceof File) {
      formData.append('img', Data.img);
    } else {
      throw new Error('Category image is required');
    }

  

    const response = await axios.post(`${baseURL}inventories/new-add-category`, formData, {
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      }
    });
    
    if (response && response.status === 200 && response.data.message === "success") {
      return response.data;
    } else {
      toast.error(`Unexpected response: ${response.data.errorMessage}`);
      console.error("Unexpected response:", response, response.data.errorMessage);
    }
  } catch (error) {
    // Handle axios errors
    if (error.response) {
      const errorMsg = error.response.data.errorMessage || error.response.data.message || 'Server error';
      toast.error(errorMsg);
      throw new Error(errorMsg);
    } else {
      toast.error(error.message || 'Request failed');
      throw error;
    }
  }
}