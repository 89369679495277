import React, { useState, useEffect } from "react";
import styles from "./Ecom.module.css";
import { useNavigate } from "react-router-dom";
import { listAllMyProducts } from "../../api/ecom";
import { getCategoryList } from "../../api/inventoryAPI";

import { toast } from "react-toastify";
import wishlist from "../../Assest/img/wishlist.svg";
import { useSelector } from "react-redux";
import manageAdditem from "../../Assest/img/manageAaaitem.svg";
import manageedit from "../../Assest/img/mage_edit.svg";

function ManageInventory() {
  const navigate = useNavigate();
  const token = useSelector((state) => state.login.token);

  const [activeTab, setActiveTab] = useState("used"); // 'new' or 'used'
  const [categories, setCategories] = useState([]);
  const [ecomData, setEcomData] = useState({
    products: [],
    priorityVendors: [
      {
        categories: [],
      },
    ],
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);


  const getAllProducts = async (searchTerm = undefined) => {
    try {
      setLoading(true);
      const params = {
        search: searchTerm,
        page: 1,
        limit: 10000,
        isNew: activeTab === "used" ? 1 : 0,
      };
      const response = await listAllMyProducts(token, params);
      setEcomData(response.data);
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  };

  useEffect(() => {
   
    const getAllCategories = async () => {
      try {
        const response = await getCategoryList(token);
        setCategories(response.data);
        console.log(response.data);
      } catch (error) {
        toast.error(error.message);
        throw error;
      }
    };
    getAllCategories();

    getAllProducts();
  }, [searchTerm, activeTab]);

  const handleSearchInputChange = (e) => {
    e.preventDefault();

    setSearchTerm(e.target.value);
    getAllProducts(searchTerm);
  };

  const viewCategoryPage = (vendorIdArray) => {
    if (vendorIdArray && vendorIdArray.length > 0) {
      const vendorId = vendorIdArray[0]; // Safely extract the first vendorId
      navigate("/ecomcategory", { state: { vendorId } });
    } else {
      console.error("Vendor ID array is empty or undefined");
      toast.error("No Vendor  found");
    }
  };

  const handleAddEcomInventory = () => {
    navigate("/EcomAddInventory");
  };

  //edit 
  const handleEdit = (id) => {
    console.log(id)
    navigate('/editEcomInventory', { state :{ productId: id }
})
  }

  //wishlist
  const handleWishlist = () => {
    console.log('wishlist')
    navigate('/wishlist')
  }

  return (
    <div className={styles.container}>
      <div className={styles.categoriesContainer}>
        <div className={styles.phonemanageInventory}>
          <span onClick={handleWishlist}>
            {" "}
            <img src={wishlist}></img>Wishlist
          </span>
        </div>

        {/* Toggle Buttons */}
        <div className={styles.toggleContainer}>
          <button
            className={`${styles.toggleButton} ${
              activeTab === "new" ? styles.activeToggle : ""
            }`}
            onClick={() => setActiveTab("new")}
          >
            New Items
          </button>
          <button
            className={`${styles.toggleButton} ${
              activeTab === "used" ? styles.activeToggle : ""
            }`}
            onClick={() => setActiveTab("used")}
          >
            Used Items
          </button>
        </div>
        <div className={styles.tab1}>
          <div className={styles.searchContainer}>
            <form
              className={`d-flex ${styles.search}`}
              onSubmit={(e) => e.preventDefault()}
            >
              <input
                className={`form-control text-custom me-2 w-30 ${styles.textCustom}`}
                type="search"
                placeholder="Search"
                aria-label="Search"
                onChange={handleSearchInputChange}
                value={searchTerm}
              />
            </form>
          </div>

          <div className={styles.manageInventory}>
            <span   onClick={handleWishlist}>
              {" "}
          
              <img src={wishlist}></img>Wishlist
            </span>
          </div>
        </div>
      </div>
      <div className={styles.cardContainer}>
        {/* Categories Scroll */}
        <div className={styles.categoriesList}>
          {categories.map((category) => (
            <div key={category.id} className={styles.categoryItem}>
              <div className={styles.categoryImage} onClick={() => navigate('/ecominventory', { state: {
              categoryId: category.id,
              showAllCategories:'true'
                  }, })}>
                <img src={category.img} alt={category.name} />
              </div>
              <span className={styles.categoryName}>{category.name}</span>
            </div>
          ))}
        </div>

        {/* Store Header */}
        <div className={styles.phonetoggleContainer}>
          {/* <button
            className={`${styles.toggleButton} ${
              activeTab === "new" ? styles.activeToggle : ""
            }`}
            onClick={() => setActiveTab("new")}
          >
            New Items
          </button> */}
          <button
            className={`${styles.toggleButton} ${
              activeTab === "used" ? styles.activeToggle : ""
            }`}
            onClick={() => setActiveTab("used")}
          >
            Used Items
          </button>
        </div>
        <div className={styles.phonetab1}>
          <div className={styles.searchContainer}>
            <form
              className={`d-flex ${styles.search}`}
              onSubmit={(e) => e.preventDefault()}
            >
              <input
                className={`form-control text-custom me-2 w-30 ${styles.textCustom}`}
                type="search"
                placeholder="Search"
                aria-label="Search"
                onChange={handleSearchInputChange}
                value={searchTerm}
              />
            </form>
          </div>

          <div className={styles.manageInventory}>
            <span>
              <img src={wishlist}></img>Wishlist
            </span>
          </div>
        </div>

        {/* Product Grid */}
        <div className={styles.productGrid}>
          <div
            className={styles.additemContainer}
            onClick={handleAddEcomInventory}
          >
      
            <img src={manageAdditem}></img>
          </div>

          {ecomData.products.map((product) => (
            <div key={product.id} className={styles.productCard}>
              <img className={styles.edit} src={manageedit}  onClick={()=>handleEdit(product.id)}/>

              <img
                src={product.mainImage}
                alt={product.name}
                className={styles.productImage}
              />
              <h3 className={styles.productName}>{product.productName}</h3>
              <div className={styles.priceContainer}>
                <span className={styles.originalPrice}>₹{product.price}</span>
                <span className={styles.discountedPrice}>
                ₹{product.pricing - product.discountedPrice}
                </span>
                <span className={styles.discount}>{product.discount}</span>
              </div>
              <div className={styles.ratingContainer}>
                <span className={styles.star}>★</span>
                <span className={styles.rating}>{product.rating}/5</span>
              </div>
              <button
                className={styles.viewDetailsButton}
                onClick={() => navigate(`/ecominventorydetail `, {state :{productId :product.id }})}
              >
                View Details
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ManageInventory;
