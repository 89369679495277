import React, { useEffect, useState } from 'react';
import { getCategoryList } from '../../api/inventoryAPI'; // API call
import { useSelector } from 'react-redux';
import styles from './category.module.css';
import { useNavigate } from 'react-router';
import defaultImg from '../../Assest/img/defaultImg.svg'

function Category() {
  const [cardData, setCardData] = useState([]); 
  const [searchTerm, setSearchTerm] = useState(''); 
  const token = useSelector((state) => state.login.token); 
  const navigate = useNavigate();

  // Fetch category list from the API
  const fetchCategoryList = async (search)  => {
    try {
      const response = await getCategoryList(token , search); 
      if (response.message === 'success') {
        setCardData(response.data); 
        console.log(response.data);
      }
    } catch (error) {
      console.error('Failed to fetch category list:', error);
    }
  };

  useEffect(() => {
    fetchCategoryList();
  }, [token]);

 const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value); 
  };

  // Handle search form submission
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    fetchCategoryList(searchTerm); // Fetch data based on the search term
  };
  const showInventoryDetail = (categoryId) => {
    navigate(`/inventoryDetail?category=${categoryId}`);
  };

  const navigateToAddInventory = () => {
    navigate('/addinventory');
  };

  return (
    <div className={styles['category-container']}>
      <div className={styles['category-top-tab']}>
        {/* Search Form */}
        <form className={`d-flex ${styles.search}`} >
          <input
            className={`form-control ${styles['text-custom']} me-2 w-30`}
            type="search"
            placeholder="Search"
            aria-label="Search"
            value={searchTerm} // Controlled input for search
            onChange={handleSearchInputChange} // Update search term on change
          />
          <button className={`btn ${styles['btn-custom']}`} type="submit" onClick={handleSearchSubmit}>
            Search
          </button>
        </form>
        {/* Add item and Catalog buttons */}
        <div className={styles['category-btn']}>
          <button className={styles.additem} onClick={navigateToAddInventory}>
            Add item
          </button>
          <button className={styles.catalog}>
            Catalog
          </button>
        </div>
      </div>

      {/* Category Cards */}
      <div className={`${styles['category-card-container']}`}>
        {cardData.length > 0 ? (
          cardData.map((category, index) => (
            <div key={index} className={`${styles.box}`}>
              <img src={category.img ? category.img : defaultImg} alt={category.name} />
              <h5 className={`card-title ${styles['card-title']}`}>{category.name}</h5>
              <a
                href="#"
                className={`btn ${styles['btn-view-more']}`}
                onClick={() => showInventoryDetail(category.categoryId)}
              >
                View More
              </a>
            </div>
          ))
        ) : (
          <div>No Category Available</div> // Show loading state
        )}
      </div>
    </div>
  );
}

export default Category;
