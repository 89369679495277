import React, { useEffect, useState } from "react";
import styles from "./addItem.module.css";
import selectarrow from "../../Assest/img/selectarrow.svg";
import plus from "../../Assest/img/plus.svg";
import minus from "../../Assest/img/minus.svg";
import { useNavigate } from "react-router";
import { addItem, addMoreInventory } from "../../api/order";
import { useSelector, useDispatch } from "react-redux";
import { creteOrderAction } from "../../Reduxstore/createOrder";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import moment from "moment-timezone";

const AddItem = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    orderId = null,
    isEditMode = false,
    newItemStartDate = null,
    status = null,
    saveData = null,
  } = location.state || {};

  console.log(saveData);
  const token = useSelector((state) => state.login.token);
  const savedItems = useSelector((state) => state.order.addItem);

  const [categories, setCategories] = useState([]);
  const [inventory, setInventory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [itemQuantities, setItemQuantities] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();


  useEffect(() => {
    const fetchData = async () => {
      const result = await addItem(token);
      const mainCategoryList = result.data.categoriesGrouped.map(
        (e) => e.categoryName
      );
      const mainInventoryList = result.data.categoriesGrouped.map(
        (e) => e.inventories
      );

      setCategories(mainCategoryList);
      setInventory(mainInventoryList);

      // Initialize quantities with saved values or zeros
      const initialQuantities = mainInventoryList.flat().map((item) => {
        const savedItem = savedItems.find(
          (saved) => saved.id === item.inventoryId
        );
        return savedItem ? savedItem.quantity : 0;
      });
      setItemQuantities(initialQuantities);
    };
    fetchData();
  }, [token, savedItems]);

  const getGlobalIndex = (catIndex, itemIndex) =>
    inventory.slice(0, catIndex).reduce((sum, inv) => sum + inv.length, 0) +
    itemIndex;

  const updateQuantity = (catIndex, itemIndex, newQuantity) => {
    const globalIndex = getGlobalIndex(catIndex, itemIndex);
    const maxQuantity = inventory[catIndex][itemIndex].availableQuantity;
    const updatedQuantities = [...itemQuantities];
    updatedQuantities[globalIndex] = Math.min(
      Math.max(newQuantity, 0),
      maxQuantity
    );
    setItemQuantities(updatedQuantities);
  };

  const handleInputChange = (e, catIndex, itemIndex) => {
    const value = parseInt(e.target.value, 10) || 0;
    updateQuantity(catIndex, itemIndex, value);
  };

  if (isEditMode && !orderId) {
    navigate("/order/orderDetail"); // Redirect back to a safe page
    return null;
  }

  const handleConfirmOrder = async () => {
    const selectedData = inventory
      .flatMap((category, categoryIndex) =>
        category.map((item, itemIndex) => {
          const globalIndex = getGlobalIndex(categoryIndex, itemIndex);
          const quantity = itemQuantities[globalIndex];
          if (quantity > 0) {
            return {
              id: item.inventoryId,
              name: item.inventoryName,
              quantity,
              godownId: item.godownId,
              godownName: item.godownName,
              price: item.rentPrice,
            };
          }
          return null;
        })
      )
      .filter(Boolean);
    console.log(selectedData, inventory);
    const selectedAddedData = inventory
      .flatMap((category, categoryIndex) =>
        category.map((item, itemIndex) => {
          const globalIndex = getGlobalIndex(categoryIndex, itemIndex);
          const quantity = itemQuantities[globalIndex];
          if (quantity > 0) {
            return {
              inventoryId: item.inventoryId,
              quantity,
              godownId: item.godownId,
            };
          }
          return null;
        })
      )
      .filter(Boolean);

    if (isEditMode || status === "pending") {
      try {
        // Convert the string into a Date object
        const formattedDate = moment(newItemStartDate, "Do MMM YYYY").format(
          "YYYY-MM-DD"
        );
        const response = await addMoreInventory(
          token,
          orderId,
          formattedDate,
          selectedAddedData
        );
        if (response.message === "success") {
          toast("successfully Add inventory");
        }
      } catch (error) {
        toast(error.message);
      }

      if (isEditMode || status === "dispatched") {
        try {
          const today = new Date();
          const todayFormattedDate = today.toISOString().split("T")[0]; // Format: YYYY-MM-DD

          const response = await addMoreInventory(
            token,
            orderId,
            todayFormattedDate,
            selectedAddedData
          );
          if (response.message === "success") {
            toast("successfully Add inventory");
          }
        } catch (error) {
          // toast(error.message);
        }
        navigate("/order/orderDetail", { state: { orderId: orderId } });
        // Navigate back to Order Detail
      } else {
        dispatch(creteOrderAction.addItem(selectedData));
        navigate("/order/createOrder");
      }
      // Navigate back to Order Detail
      navigate("/order/orderDetail", { state: { orderId: orderId } });
    } else {
      dispatch(creteOrderAction.addItem(selectedData));
      navigate("/order/createOrder");
    }
  };

  const handleChangeDate = (event) => {
    const { name, value } = event.target;
    // setData((prev) => ({ ...prev, [name]: value }));
    console.log(name ,'-',value);
  }
  return (
    <div className={styles.container}>
      <h1>Add Item</h1>
     {isEditMode && <div className={styles.dateSelector}>
        <div>
          
        <label>Start Date</label>
        <input type='date' value={startDate} name='startDate' onChange={handleChangeDate}></input>
        </div>
        <div>
          
          <label>End Date</label>
          <input type='date'  value={endDate} name='endDate' onChange={handleChangeDate}></input>
          </div>
      </div>}
      <div className={styles.cardContainer}>
        {categories.map((category, catIndex) => (
          <div key={catIndex}>
            <div
              className={styles.category}
              onClick={() =>
                setSelectedCategory(
                  catIndex === selectedCategory ? null : catIndex
                )
              }
            >
              <h5>{category}</h5>
              <img src={selectarrow} alt="Select arrow" />
            </div>
            {selectedCategory === catIndex && (
              <div className={styles.cardBox}>
                {inventory[catIndex].map((item, itemIndex) => {
                  const globalIndex = getGlobalIndex(catIndex, itemIndex);
                  return (
                    <div key={itemIndex} className={`card-body ${styles.box}`}>
                      <div className={styles.img}>
                        <img
                          src={item.img}
                          className={styles.cardImg}
                          alt={item.inventoryName}
                        />
                      </div>
                      <div className={styles.name}>
                        <h5 className={`card-title ${styles.title}`}>
                          {item.inventoryName}
                        </h5>
                      </div>
                      <div className={styles.available}>
                        <p>Available Quantity</p>
                      </div>
                      <div className={styles.quantity}>
                        <p>{item.availableQuantity}</p>
                        <div className={styles.additem}>
                          <img
                            src={minus}
                            alt="minus"
                            onClick={() =>
                              updateQuantity(
                                catIndex,
                                itemIndex,
                                itemQuantities[globalIndex] - 1
                              )
                            }
                          />
                          <input
                            type="number"
                            value={itemQuantities[globalIndex]}
                            onChange={(e) =>
                              handleInputChange(e, catIndex, itemIndex)
                            }
                          />
                          <img
                            src={plus}
                            alt="plus"
                            onClick={() =>
                              updateQuantity(
                                catIndex,
                                itemIndex,
                                itemQuantities[globalIndex] + 1
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        ))}
      <div className={styles.button}>
        <button onClick={() => navigate(-1)}>Cancel</button>
        <button onClick={handleConfirmOrder}>Save</button>
      </div>
      </div>
    </div>
  );
};

export default AddItem;
