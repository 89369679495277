import React  from 'react';
import Layout from '../Layout/Layout';
import AddInventory from '../../inventory/AddInventory';


function addInventoryFlow() {


  return (
    <Layout child={'Add Item'}>
    <AddInventory/>
  </Layout>
  )
}

export default addInventoryFlow
