import React, { useState } from 'react';
import styles from './addGodownModal.module.css';
import save from "../../Assest/img/save.svg";
import cancel from "../../Assest/img/cancel.svg";
import { useSelector } from 'react-redux';
import { addCategory } from '../../api/inventoryAPI';
import { toast } from 'react-toastify';

function AddCategoryModal({ openModal, closeModal, updateData }) {
  const [data, setData] = useState({
    name: '',
    img: null
  });
  const token = useSelector(state => state.login.token);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData(prev => ({ ...prev, [name]: value }));
  };

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setData(prev => ({ ...prev, img: file }));
    } else {
      console.log('No file selected');
    }
  };

  const handleSave = async () => {
    try {
      if (!data.name || !data.name.trim()) {
        toast.error('Please enter a category name');
        return;
      }

      if (!data.img) {
        toast.error('Please select an image');
        return;
      }

      const response = await addCategory(token, data);
      if (response.message === 'success') {
        updateData();
        toast.success('New Category Successfully Added');
        setData({ name: '', img: null });
        closeModal(); 
      }
    } catch (error) {
      console.error("Error adding category:", error);
      toast.error(error.message || 'Failed to add category');
    } 
  };

  if (!openModal) return null;

  return (
    <div className={styles.modalOverlay} onClick={closeModal}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <div className={styles.profile}>
          <p>Add Category</p>
          <button className={styles.modalClose} onClick={closeModal}>X</button>
        </div>

        <div className={styles.availUnit}>
          <label>Name</label>
          <input
            type="text"
            value={data.name || ''}
            onChange={handleChange}
            name="name"
            required
          />
          <div>
            {data.img && (
              <img
                src={URL.createObjectURL(data.img)}
                alt="Category Preview"
                 accept="image/*"
                  capture="environment"
                style={{ maxWidth: '200px', marginTop: '10px' }}
              />
            )}
            <input 
              type="file" 
              onChange={handleImageChange}
              accept="image/*"
            />
          </div>
        </div>

        <div className={styles.saveCancel}>
          <button className={styles.cancel} onClick={closeModal}>
            <img src={cancel} alt="Cancel" /> Cancel
          </button>
          <button className={styles.save} onClick={handleSave}>
            <img src={save} alt="Save" /> Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddCategoryModal;
