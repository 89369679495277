import React from 'react';
import Layout from "../Layout/Layout";
import EditEcomInventory from '../../Ecom/EditEcomInventory';

function EditEcomInventoryFlow() {
    return (
        <Layout child="Edit Inventory">
            <EditEcomInventory />
        </Layout>
    );
}

export default EditEcomInventoryFlow;
