import React from 'react';
import Layout from '../Layout/Layout';
import Subscription from '../../subscription/Subscription'

function SubscriptionFlow() {
  return (
    <Layout  child={'Subscription'}>
    <Subscription />
  </Layout>
  );
}

export default SubscriptionFlow;
