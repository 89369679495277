import React from "react";
import Layout from "../Layout/Layout";
import Ecom from "../../Ecom/Ecom";

function EcomFlow() {
  return (
    <Layout child="Ecom">
      <Ecom />
    </Layout>
  );
}

export default EcomFlow;
