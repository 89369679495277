import React from 'react';
import Layout from '../Layout/Layout';
import Profile from '../../Home/Profile';

function ProfileFlow() {
  return (
    <Layout  child={'Profile'}>
    <Profile />
  </Layout>
  );
}

export default ProfileFlow;
