import React, { useState, useEffect } from "react";
import styles from "./Ecom.module.css";
import { useNavigate } from "react-router-dom";
import {
  listAllCategories,
  listAllProducts,
  addWishlist,
  getWishlist,
} from "../../api/ecom";
import { toast } from "react-toastify";
import wishlistIcon from "../../Assest/img/wishlist.svg";
import fullWishlistIcon from "../../Assest/img/fullWishlist.svg";
import { useSelector } from "react-redux";

function Ecom() {
  const [activeTab, setActiveTab] = useState("used"); // 'new' or 'used'
  const [displayCount, setDisplayCount] = useState(4);
  const [categories, setCategories] = useState([]);
  const [ecomData, setEcomData] = useState({
    products: [],
    priorityVendors: [
      {
        categories: [],
      },
    ],
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [wishlistProducts, setWishlistProducts] = useState([]);

  const navigate = useNavigate();
  const token = useSelector((state) => state.login.token);

  // Fetch all products
  const getAllProducts = async (search = "") => {
    try {
      setLoading(true);
      const params = {
        search,
        page: 1,
        limit: 10000,
        isNew: activeTab === "used" ? 1 : 0,
      };
      const response = await listAllProducts(token, params);
      setEcomData(response.data);
      setWishlistProducts(response.data.products
        .filter((product) => product.isWhishListed)
        .map((product) => product.id));
    } catch (error) {
      toast.error("Failed to fetch products: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  // Fetch wishlist
  const fetchWishlist = async () => {
    try {
      const response = await getWishlist(token);
      setWishlistProducts(response.data.whishListedProducts.map((product) => product.id));
    } catch (error) {
      toast.error("Failed to fetch wishlist: " + error.message);
    }
  };

  // Handle wishlist toggle
  const controlWishlist = async (id) => {
    try {
      await addWishlist(token, id);
      setWishlistProducts((prev) =>
        prev.includes(id) ? prev.filter((productId) => productId !== id) : [...prev, id]
      );
    } catch (error) {
      toast.error("Failed to update wishlist: " + error.message);
    }
  };

  useEffect(() => {
    const handleResize = () => setDisplayCount(window.innerWidth <= 640 ? 2 : 6);
    handleResize();
    window.addEventListener("resize", handleResize);

    const fetchData = async () => {
      try {
        const categoriesResponse = await listAllCategories(token);
        setCategories(categoriesResponse.data);
        fetchWishlist();
        getAllProducts();
      } catch (error) {
        toast.error("Failed to fetch data: " + error.message);
      }
    };

    fetchData();
    return () => window.removeEventListener("resize", handleResize);
  }, [activeTab]);

  const handleSearch = (e) => {
    e.preventDefault();
    getAllProducts(searchTerm);
  };

  const handleWishlist = () => {
    navigate("/wishlist");
  };

  const handleManageInventory = () => {
    navigate("/manageInventory");
  };


  const handleSearchInputChange = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
    getAllProducts(searchTerm);
  };

  const viewCategoryPage = (vendorIdArray) => {
    if (vendorIdArray && vendorIdArray.length > 0) {
      const vendorId = vendorIdArray[0];
      navigate("/ecomcategory", { state: { vendorId } });
    } else {
      console.error("Vendor ID array is empty or undefined");
      toast.error("No Vendor found");
    }
  };

  const navigateToInventoryPage = (product, vendor) => {
    console.log(product, vendor)
    navigate("/ecominventory", {
      state: {
        categoryId: product.categoryId,
        vendorId: vendor.vendorId,
      },
    });
  };
console.log(categories)
  return (
    <div className={styles.container}>
      <div className={styles.categoriesContainer}>
        <div className={styles.phonemanageInventory}>
          <button
            className={styles.sellUsedItem}
            onClick={handleManageInventory}
          >
            Sell Used Items
          </button>
          <span onClick={handleWishlist}>
            <img src={wishlistIcon} alt="Wishlist" /> Wishlist
          </span>
        </div>

        {/* Toggle Buttons */}
        <div className={styles.toggleContainer}>
          <button
            className={`${styles.toggleButton} ${
              activeTab === "new" ? styles.activeToggle : ""
            }`}
            onClick={() => setActiveTab("new")}
          >
            New Items
          </button>
          <button
            className={`${styles.toggleButton} ${
              activeTab === "used" ? styles.activeToggle : ""
            }`}
            onClick={() => setActiveTab("used")}
          >
            Used Items
          </button>
        </div>
        <div className={styles.tab1}>
          <div className={styles.searchContainer}>
            <form
              className={`d-flex ${styles.search}`}
              onSubmit={(e) => e.preventDefault()}
            >
              <input
                className={`form-control text-custom me-2 w-30 ${styles.textCustom}`}
                type="search"
                placeholder="Search"
                aria-label="Search"
                onChange={handleSearchInputChange}
                value={searchTerm}
              />
            </form>
          </div>

          <div className={styles.manageInventory}>
            <button
              className={styles.sellUsedItem}
              onClick={handleManageInventory}
            >
              Sell Used Items
            </button>
            <span onClick={handleWishlist}>
              <img src={wishlistIcon} alt="Wishlist" /> Wishlist
            </span>
          </div>
        </div>
      </div>
      <div className={styles.cardContainer}>
        {/* Categories Scroll */}
        <div className={styles.categoriesList}>
          {categories.map((category) => (
            <div
              key={category.id}
              className={styles.categoryItem}
              onClick={() =>
                viewCategoryPage(category.vendorId) 
              }
            >
              <div className={styles.categoryImage}>
                <img src={category.img} alt={category.name} />
              </div>
              <span className={styles.categoryName}>{category.name}</span>
            </div>
          ))}
        </div>

        {/* Store Header */}
        <div className={styles.phonetoggleContainer}>
          <button
            className={`${styles.toggleButton} ${
              activeTab === "new" ? styles.activeToggle : ""
            }`}
            onClick={() => setActiveTab("new")}
          >
            New Items
          </button>
          <button
            className={`${styles.toggleButton} ${
              activeTab === "used" ? styles.activeToggle : ""
            }`}
            onClick={() => setActiveTab("used")}
          >
            Used Items
          </button>
        </div>
        <div className={styles.phonetab1}>
          <div className={styles.searchContainer}>
            <form
              className={`d-flex ${styles.search}`}
              onSubmit={(e) => e.preventDefault()}
            >
              <input
                className={`form-control text-custom me-2 w-30 ${styles.textCustom}`}
                type="search"
                placeholder="Search"
                aria-label="Search"
                onChange={handleSearchInputChange}
                value={searchTerm}
              />
            </form>
          </div>

          <div className={styles.manageInventory}>
            <button className={styles.sellUsedItem}>Sell Used Items</button>
            <span onClick={handleWishlist}>
              <img src={wishlistIcon} alt="Wishlist" /> Wishlist
            </span>
          </div>
        </div>
        {ecomData.priorityVendors.map((vendor) => (
          <div className={styles.storeContainer} key={vendor.vendorId}>
            <div className={styles.storeHeader}>
              <h2 className={styles.storeName}>{vendor.storeName}</h2>

              <button
                className={styles.viewShopButton}
                onClick={() =>
                  viewCategoryPage(
                    ecomData.priorityVendors.map((e) => e.vendorId)
                  )
                }
              >
                View Shop
              </button>
            </div>
            <div className={styles.storeCategoryContainer}>
              {vendor.categories.slice(0, displayCount).map((product) => (
                <div key={product.id} className={styles.productCardadd}>
                  <img
                    src={product.categoryImg}
                    alt={product.categoryName}
                    className={styles.productImageadd}
                  />
                  <h3 className={styles.productName}>{product.categoryName}</h3>
                  <div className={styles.viewDetailsButtonadd}>
                    <button
                      onClick={() => navigateToInventoryPage(product, vendor)}
                    >
                      View More
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
        {/* Product Grid */}
        <div className={styles.productGrid}>
          {ecomData.products.map((product) => (
            <div key={product.id} className={styles.productCard}>
              <img
                className={styles.edit}
                src={
                  wishlistProducts.includes(product.id)
                    ? fullWishlistIcon
                    : wishlistIcon
                }
                alt="Wishlist Icon"
                onClick={() => controlWishlist(product.id)}
              />
              <img
                src={product.mainImage}
                alt={product.name}
                className={styles.productImage}
              />
              <h3 className={styles.productName}>{product.productName}</h3>
              <div className={styles.priceContainer}>
                <span className={styles.originalPrice}>
                  ₹{product.pricing}
                </span>
                <span className={styles.discountedPrice}>
                  ₹{product.pricing - product.discountedPrice}
                </span>
                <span className={styles.discount}>{product.discount}</span>
              </div>
              <div className={styles.ratingContainer}>
                <span className={styles.star}>★</span>
                <span className={styles.rating}>{product.rating}/5</span>
              </div>
              <button
                className={styles.viewDetailsButton}
                onClick={() =>
                  navigate(`/ecominventorydetail`, {
                    state: { productId: product.id },
                  })
                }
              >
                View Details
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Ecom;
