import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./Inventory.module.css";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  listAllInventory,
  storeDetails,
  listAllCategories,
} from "../../api/ecom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

function Inventory() {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    categoryId,
    vendorId,
    showAllCategories = false,
  } = location.state || {}


  console.log(categoryId, vendorId, showAllCategories);
  const token = useSelector((state) => state.login.token);

  const [activeTab, setActiveTab] = useState("new");
  const [allInventory, setAllInventory] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [selectCategoryId, setSelectCategoryId] = useState(categoryId);
  const [searchTerm, setSearchTerm] = useState("");

  console.log(location, categoryId, vendorId);

  useEffect(() => {
    // Redirect if vendorId is not available and showAllCategories is false
    if (!vendorId && !showAllCategories) {
      toast.error("Vendor information is missing");
      navigate("/ecom"); // or wherever you want to redirect
      return;
    }
  }, [vendorId, showAllCategories, navigate]);

 

  useEffect(() => {
    const categoryList = async () => {
      if (!showAllCategories && vendorId) {
        try {
          const response = await storeDetails(token, vendorId, {});
          console.log(response.data.categoriesForVendor);
          setAllCategories(response.data.categoriesForVendor);
        } catch (error) {
          toast.error(error.message);
          throw error;
        }
      } else {
        try {
          const response = await listAllCategories(token, categoryId);
          console.log(response.data.categoriesForVendor);
          setAllCategories(response.data);
        } catch (error) {
          toast.error(error.message);
          throw error;
        }
      }
    };
    categoryList();
  }, [showAllCategories, token, vendorId, selectCategoryId, categoryId]);

  const inventoryData = async (searchTerm=undefined) => {
    try {
      const params = {
        search: searchTerm,
        page: 1,
        limit: 10000,
        isNew: activeTab === "new" ? 1 : 0,
        categoryId:selectCategoryId
      };
      const response = await listAllInventory(token, params);
      console.log(response.data.products);
      setAllInventory(response.data.products);
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  };

  useEffect(() => {

    if (selectCategoryId) {
      inventoryData(searchTerm);
    }
  }, [selectCategoryId , activeTab ,searchTerm]);

  const handleSelectCategory = (categoryId) => {
    const id = categoryId.target.value;
    console.log("update id", id);
    setSelectCategoryId(id);
  };

  const handleSearchInputChange = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
    inventoryData(e.target.value)
  };

  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <div className={styles.toggleContainer}>
          <button
            className={`${styles.toggleButton} ${
              activeTab === "new" ? styles.activeToggle : ""
            }`}
            onClick={() => setActiveTab("new")}
          >
            New Items
          </button>
          <button
            className={`${styles.toggleButton} ${
              activeTab === "used" ? styles.activeToggle : ""
            }`}
            onClick={() => setActiveTab("used")}
          >
            Used Items
          </button>
        </div>
        <div className={styles.searchContainer}>
          <form
            className={`d-flex ${styles.search}`}
            onSubmit={(e) => e.preventDefault()}
          >
            <input
              className={`form-control text-custom me-2 w-30 ${styles.textCustom}`}
              type="search"
              placeholder="Search"
              aria-label="Search"
                onChange={handleSearchInputChange}
                value={searchTerm}
            />
          </form>{" "}
        </div>
      </div>
      <div className={styles.selectContainer}>
        {/* <img 
                  src={categoryIcon} 
                  alt="category" 
                  className={styles.categoryIcon} 
              /> */}
        <select
          className={styles.select}
          value={selectCategoryId || ''}
          onChange={handleSelectCategory}
        >
          <option value="" disabled>
            {" "}
            Select Category
          </option>
          {allCategories && allCategories.length > 0 ? (
            allCategories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))
          ) : (
            <option value="" disabled>
              No categories available
            </option>
          )}
        </select>
      </div>
      <div className={styles.productsGrid}>
        {allInventory && allInventory.length > 0 ? (
          allInventory.map((product) => {
            const discountPercentage =
              product.pricing && product.discountedPrice
                ? Math.round(
                    ((product.pricing - product.discountedPrice) /
                      product.pricing) *
                      100
                  )
                : 0;

            return (
              <div key={product.id} className={styles.productCard}>
                <img
                  src={product.mainImage}
                  alt={product.productName}
                  className={styles.productImage}
                />
                <h3 className={styles.productName}>{product.productName}</h3>
                <div className={styles.priceContainer}>
                  <span className={styles.originalPrice}>
                    ₹{product.pricing}
                  </span>
                  <span className={styles.discountedPrice}>
                    ₹{product.discountedPrice}
                  </span>
                  <span className={styles.discount}>
                    {discountPercentage > 0
                      ? `${discountPercentage}% OFF`
                      : "No Discount"}
                  </span>
                </div>
                <div className={styles.ratingContainer}>
                  <span className={styles.star}>★</span>
                  <span className={styles.rating}>{product.averageRating}</span>
                </div>
                <button
                  className={styles.viewMore}
                  onClick={() =>
                    navigate(`/ecominventorydetail `, {
                      state: { productId: product.id },
                    })
                  }
                >
                  View more
                </button>
              </div>
            );
          })
        ) : (
          <div className={styles.noProducts}>No products available</div>
        )}
      </div>
    </div>
  );
}

export default Inventory;
