import React, { useState, useEffect } from "react";
import "./supercategory.css";
import loginSideScreen from "../../Assest/img/loginSideScreen.svg";
import logo from "../../Assest/img/logo.svg";
import event from '../../Assest/img/event.svg';
import { useNavigate } from "react-router";
import { getSuperCategory , signUp} from "../../api/signup";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfile } from "../../api/login";
import { loginAction } from "../../Reduxstore/login";

function SuperCategory() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const token = useSelector(state => state.login.token);

  const [id ,setId] =useState(0)
  const [data, setData] = useState([]);
  const [isButtonDisable, setButtonDisable] = useState(true);

  useEffect(() => {
    const Category = async () => {
      const response = await getSuperCategory(token, data)
      console.log(response)

      if (response.message === 'success') {
        setData(response.data)
      }
    }
    Category()
  }, [])
  
  const handleSelectCategory = async()=> {
    const result = await signUp(token, { superCategoryId: id })
    if (result.message === 'success') {
    const profile =  await fetchProfile(token)
    if(profile.message==='success')
      dispatch(loginAction.userProfile(profile.data))
      navigate('/category')
    }
    
  }

  const getId = (e) => {
    console.log(e.target.id)
    setId(e.target.id)
setButtonDisable(false)

  }

  return (
    <div className="category-container d-flex justify-content-center align-items-center">
      <div className="category-left-screen">
        <img src={loginSideScreen} />
      </div>
      <div className="category-right-screen">
        <div className="category-form-container">
          <div className="category-web-img">
            <img className="logo" src={logo} />
          </div>
          <div className="category-head">Select Category</div>
          <div className="category-details">
            {data.map((e) => (<button id={e.id} onClick={getId}   className={`detail ${+id === e.id ? "selected-category" : ""}`}><img id={e.id} src={e.img}/>{e.name}</button>))}

           
          </div>

          <div className="btn-category">
            <button className=" btn btn-submit" onClick={handleSelectCategory} disabled={isButtonDisable} >Submit</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuperCategory;
