import { Disclosure, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginAction } from '../../Reduxstore/login';
import { useEffect, useState } from 'react';
import { fetchProfile } from "../../api/login";
import defaultImg from '../../Assest/img/defaultImg.svg'

function Navbar({child}) {
  const dispatch = useDispatch();
  const navigate = useNavigate()

const [img , setImg] = useState(defaultImg)
  const token = useSelector((state) => state.login.token);

  useEffect(() => {
    const getProfile = async () => {
      const result = await fetchProfile(token);
      // setProfileData(result.data);
      setImg(result.data.logo)
    };
    getProfile();
  }, [token]);


  const signOut = () => {
    localStorage.removeItem("token");
    dispatch(loginAction.logOut())
  }

  const handleProfile = () => {
    navigate('/profile')
  }

  const handleSubscription =() => {
    navigate('/dashboard/subscription')
  }
  return (
    <Disclosure as="nav" className=" navv header bg-gradient-to-r from-[#6F0AAD] via-[#600AAD] to-[#6108AD]">
    <div className="max-auto px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">
            <div className="flex flex-1 items-center justify-between sm:justify-between">
                <div className="flex flex-shrink-0 items-center">
                    {/* <img
                        src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                        alt="Your Company"
                        className="h-8 w-auto mx-4"
                    /> */}
                </div>

                <div className=" sm:flex flex-1 justify-start ">
                    <a href="/dashboard" className="text-white font-semibold text-3xl  hidden sm:block">
                    {child ? child : "Renter Book"}
                    
                    </a>
                    {/* Sidebar Toggle Button */}
 {/* <button
              onClick={handleToggleSidebar}
              className="text-gray-400 hover:text-white lg:hidden "
            >
             
               
             <Bars3Icon className="block h-6 w-6" />              
            </button> */}
              <p className="text-white font-semibold text-3xl  lg:hidden sm:block"> {child ? child : "Renter Book"}</p>
                </div>
          </div>
          {/* Profile and Notifications */}
          <div className="absolute inset-y-0 right-0 flex items-center pr-2">
            <button
              className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white"
              type="button"
            >
              <BellIcon className="h-6 w-6" />
            </button>
            <Menu as="div" className="relative ml-3">
              <div>
                <MenuButton className="flex rounded-full bg-gray-800 text-sm">
                  <img
                    className="h-8 w-8 rounded-full"
                    src={img}
                    alt="User"
                  />
                </MenuButton>
              </div>
              <MenuItems className="absolute right-0 z-10 mt-2 w-48 rounded-md bg-white py-1 shadow-lg">
                <MenuItem onClick={handleProfile}>
                  <a href="#" className="block px-4 py-2 text-sm text-gray-700">
                    Your Profile
                  </a>
                </MenuItem>
                <MenuItem onClick={handleSubscription}>
                  <a href="#" className="block px-4 py-2 text-sm text-gray-700">
                    Subscription
                  </a>
                </MenuItem>
                <MenuItem onClick={signOut}>
                  <a href="#" className="block px-4 py-2 text-sm text-gray-700">
                    Sign out
                  </a>
                </MenuItem>
              </MenuItems>
            </Menu>
          </div>
        </div>
      </div>
              </Disclosure>
  );
}

export default Navbar;
