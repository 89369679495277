import React from "react";
import Layout from "../Layout/Layout";
import ManageInventory from "../../Ecom/ManageInventory";

function ManageInventoryFlow() {
  return (
    <Layout child="Manage Inventory">
      <ManageInventory />
    </Layout>
  );
}

export default ManageInventoryFlow;
