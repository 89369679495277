import { baseURL } from "../config";
import axios from "axios";
import { toast } from "react-toastify";
import { showApiErrorToast } from "./utils";
  

  
export async function addItem(token) {
    try {
       

   const response = await axios.get(`${baseURL}inventories/list-grouped-inventory`,{
    headers: {
      Authorization: token,
    } 
   })
        if (response && response.status === 200 && response.data.message === 'success') {
            return response.data;
        }else {
          console.error("Unexpected response:", response ,response.data.errorMessage);
         
          }
    } catch (error) {
        
      showApiErrorToast(error)
      toast.error(`Error: ${error || error}`);
   
    throw error;
    }
}

  
export async function createOrder(token , startDate,endDate,partyId,gst,discount,advance,finalAmount,isQuotationOrder,cartItems) {
  try {
     

    const response = await axios.post(`${baseURL}orders/create`, {startDate,endDate,partyId,gst,discount,advance,finalAmount,isQuotationOrder,cartItems},{
  headers: {
    Authorization: token,
  } 
 })
      if (response && response.status === 200 && response.data.message === 'success') {
          return response.data;
      }else {
        console.error("Unexpected response:", response ,response.data.errorMessage);
       
        }
  } catch (error) {
      
    showApiErrorToast(error)
    toast.error(`Error: ${error || error}`);
 
  throw error;
  }
}


export async function orderList(token,search, timeFilter) {
  try {
     
 const response = await axios.get(`${baseURL}orders/all?searchKey=${search}&timeFilter=${timeFilter}`,{
  headers: {
    Authorization: token,
  } 
 })
      if (response && response.status === 200 && response.data.message === 'success') {
          return response.data;
      }else {
        console.error("Unexpected response:", response ,response.data.errorMessage);
       
        }
  } catch (error) {
      
    showApiErrorToast(error)
    toast.error(`Error: ${error || error}`);
 
  throw error;
  }
}


export async function orderDetails(token , orderId) {
  try {
     
    const response = await axios.get(`${baseURL}orders/details/${orderId}`,{
  headers: {
    Authorization: token,
  } 
 })
      if (response && response.status === 200 && response.data.message === 'success') {
          return response.data;
      }else {
        console.error("Unexpected response:", response ,response.data.errorMessage);
       
        }
  } catch (error) {
      
    showApiErrorToast(error)
    toast.error(`Error: ${error || error}`);
 
  throw error;
  }
}

export async function dispatchOrder(token , orderId) {
  try {
     
    const response = await axios.put(`${baseURL}orders/dispatch/${orderId}`, {},{
  headers: {
    Authorization: token,
  } 
 })
      if (response && response.status === 200 && response.data.message === 'success') {
          return response.data;
      }else {
        console.error("Unexpected response:", response ,response.data.errorMessage);
       
        }
  } catch (error) {
      
    showApiErrorToast(error)
    toast.error(`Error: ${error || error}`);
 
  throw error;
  }
}

export async function addExtraCharge(token , orderId ,extraCharges) {
  try {
     
    const response = await axios.post(`${baseURL}orders/extra-charges/${orderId}`, {extraCharges},{
  headers: {
    Authorization: token,
  } 
 })
      if (response && response.status === 200 && response.data.message === 'success') {
          return response.data;
      }else {
        console.error("Unexpected response:", response ,response.data.errorMessage);
       
        }
  } catch (error) {
      
    showApiErrorToast(error)
    toast.error(`Error: ${error || error}`);
 
  throw error;
  }
}

export async function returnItem(token , orderId ,returnCart) {
  try {
     
    const response = await axios.post(`${baseURL}orders/returns`,{orderId , returnCart},{
  headers: {
    Authorization: token,
  } 
 })
      if (response && response.status === 200 && response.data.message === 'success') {
          return response.data;
      }else {
        console.error("Unexpected response:", response ,response.data.errorMessage);
       
        }
  } catch (error) {
      
    showApiErrorToast(error)
    toast.error(`Error: ${error || error}`);
 
  throw error;
  }
}

export async function damageItem(token , orderId ,damageCart) {
  try {
     
    const response = await axios.post(`${baseURL}orders/damages`,{orderId , damageCart},{
  headers: {
    Authorization: token,
  } 
 })
      if (response && response.status === 200 && response.data.message === 'success') {
          return response.data;
      }else {
        console.error("Unexpected response:", response ,response.data.errorMessage);
       
        }
  } catch (error) {
      
    showApiErrorToast(error)
    toast.error(`Error: ${error || error}`);
 
  throw error;
  }
}


export async function completeOrder(token , orderId , paymentMode , finalDiscount) {
  try {
     
    const response = await axios.put(`${baseURL}orders/complete`, {orderId, paymentMode , finalDiscount},{
  headers: {
    Authorization: token,
  } 
 })
      if (response && response.status === 200 && response.data.message === 'success') {
          return response.data;
      }else {
        console.error("Unexpected response:", response ,response.data.errorMessage);
        }
  } catch (error) {
      
    showApiErrorToast(error)
    toast.error(`Error: ${error || error}`);
 
  throw error;
  }
}

export async function addMoreInventory(token , orderId ,newItemStartDate , newCartItems) {
  try {
     
    const response = await axios.post(`${baseURL}orders/add-items`,{orderId , newItemStartDate , newCartItems},{
  headers: {
    Authorization: token,
  } 
 })
      if (response && response.status === 200 && response.data.message === 'success') {
          return response.data;
      }else {
        console.error("Unexpected response:", response ,response.data.errorMessage);
       
        }
  } catch (error) {
      showApiErrorToast(error)
    console.error("Server responded with an error:", error);
 
  throw error;
  }
}

export async function addNewDateForOrder(token , orderId ,newStartDate , newEndDate) {
  try {
     
    const response = await axios.post(`${baseURL}orders/edit-dates`,{orderId , newStartDate , newEndDate},{
  headers: {
    Authorization: token,
  } 
 })
      if (response && response.status === 200 && response.data.message === 'success') {
          return response.data;
      }else {
        console.error("Unexpected response:", response ,response.data.errorMessage);
       
        }
  } catch (error) {
      
    showApiErrorToast(error)
    toast.error(`Error: ${error || error}`);
 
  throw error;
  }
}


export async function cancelOrder(token, orderId) {
  try {
    const response = await axios.delete(`${baseURL}orders/cancel/${orderId}`, {
      headers: {
        Authorization: token,
      }
    });

    if (response && response.status === 200 && response.data.message === 'success') {
      return response.data;
    } else {
      console.error("Unexpected response:", response, response.data.errorMessage);
    }
  } catch (error) {
    showApiErrorToast(error)
    toast.error(`Error: ${error || error}`);
    throw error;
  }
}