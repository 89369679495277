import React, { useState, useEffect } from "react";
import styles from "./DetailInventory.module.css";
import { FaSearch, FaStar, FaStarHalf, FaUser } from "react-icons/fa";
import { useSelector } from "react-redux";
import { productDetail, requestContact } from "../../api/ecom";
import { TbTruckReturn } from "react-icons/tb";
import defaultImage from "../../Assest/img/defaultProfile.svg";
import ReactStars from "react-rating-stars-component";
import phone from "../../Assest/img/phone.svg";
import { useLocation } from "react-router";
import { addRating } from "../../api/ecom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const DetailInventory = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const productId = location.state || "";
  const token = useSelector((state) => state.login.token);
  console.log(productId)
  const [product, setProduct] = useState({
    images: [],
    specifications: [],
    reviews: [],
    averageRating: 1,
  });
  const [isContactRequested, setIsContactRequested] = useState(false);
  const [ratings, setRatings] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [showAllReview, setShowAllReview] = useState();
  const [PhoneNumber, setPhoneNumber] = useState();
  const [rating, setRating] = useState(0);
  const [reviewText, setReviewText] = useState("");

  useEffect(() => {
    const fetchProductDetail = async () => {
      try {
        const response = await productDetail(token, productId.productId);
        setProduct(response.data);
        setRatings(response.data.ratings);
        setReviews(response.data.reviews);
        setIsContactRequested(response.data.isContactRequested);
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };
    console.log(product);

    const requestNumber = async () => {
      try {
        const response = await requestContact(token, productId.productId);
        setPhoneNumber(response.data.contact.phone);
        console.log(response.data.contact.phone);
      } catch (error) {
        console.error("Error requesting contact:", error);
      }
    };

    fetchProductDetail();
    requestNumber();
  }, [token, productId , isContactRequested]);

  const sendRequestForContact = () => {
    setIsContactRequested(true);
  };

  const discountPercentage =
    ((product.pricing - product.discountedPrice) / product.pricing) * 100;

  const viewAllReview = () => {
    setShowAllReview(true);
  };
  const totalRatings = ratings.length;
  const ratingCounts = {};

  // Count occurrences of each rating
  ratings.forEach((r) => {
    ratingCounts[r.rating] = (ratingCounts[r.rating] || 0) + 1;
  });

    //addRating
    const handleAddRating = async(e) => {
      try {
        e.preventDefault()
        if (rating > 0 && reviewText.length > 0) {
          const   response = await addRating(token,productId.productId, rating , reviewText);
              console.log(rating, reviewText);
          
              if (response.message === 'success') {
                setRating(0)
                setReviewText('')
                const res= await productDetail(token, productId.productId);
                setProduct(res.data);
                setRatings(res.data.ratings);
                setReviews(res.data.reviews);
              } 
  
        }else {
          alert('Fill your review First') 
         }
      } catch (error) {
        console.error("Error requesting contact:", error);
      }
    }
  
    //view shop 
    const viewCategoryPage = (vendorId) => {
       try{
        navigate("/ecomcategory", { state: { vendorId } });
      } catch {
        console.error("Vendor ID array is empty or undefined");
        toast.error("No Vendor  found");
      }
    
  };
  
  const rate = `${(product.averageRating / 5) * 100}%`;        

  return (
    <div className={styles.container}>
      <div className={styles.cardContainer}>
        {/* Product Details Section */}
        <div className={styles.productDetails}>
          <div className={styles.imageSection}>
            <div className={styles.imageContainer}>
              <img src={product.images[0]} alt={product.name} />
            </div>
            <div className={styles.imageDetails}>
              {product.images.map(
                (image, index) =>
                  index > 0 && (
                    <div className={styles.imageDetailsItem}>
                      <img src={image} alt={product.name} />
                    </div>
                  )
              )}
            </div>
            <div className={styles.returnPolicyContainer}>
              <div className={styles.policyHeader}>
                <TbTruckReturn className={styles.policyIcon} />
                <h3>Return Policy</h3>
              </div>
              <div className={styles.policyContent}>
                <span>
                  For more information, visit our Return Policy page Lorem ipsum
                  dolor sit amet consectetur adipisicing elit. Optio earum
                  reiciendis nihil incidunt, beatae praesentium cumque dolorum
                  suscipit non at repellat assumenda ex fuga vel. Qui nisi a
                  suscipit odio. Lorem ipsum dolor sit amet, consectetur
                  adipisicing elit. Perferendis aperiam enim iste soluta
                  mollitia laborum totam, incidunt eum earum, aliquid voluptas
                  necessitatibus quos id provident accusantium voluptatum
                  voluptatibus eveniet maiores?
                </span>
              </div>
            </div>
          </div>
          <div className={styles.detailsSection}>
            <div className={styles.detailsContainer}>
              <div className={styles.name}>{product.productName}</div>
              <div className={styles.priceContainer}>
                <span className={styles.discountedPrice}>
                  ₹{product.discountedPrice}
                </span>
                <span className={styles.originalPrice}>₹{product.pricing}</span>
                <span className={styles.discountedPercentage}>
                  {discountPercentage}% off
                </span>
              </div>

              {/* Rating Section */}
              <div className={styles.ratingBox}>
                <div className={styles.ratingScore}>
                  <span className={styles.score}>
                    {product.averageRating}/5
                  </span>
                  <div className={styles.stars}>
                    <FaStar /> Rating
                  </div>
                  <span className={styles.reviews}>
                    {product.ratingCount} Reviews
                  </span>
                </div>
              </div>

              {/* Delivery Section */}
              <div className={styles.deliverySection}>
                <div className={styles.deliveryInfo}>
                  <p>Prices Inclusive of Taxes</p>
                  <div className={styles.phone} onClick={sendRequestForContact}>
                    {isContactRequested ? (
                      <span className={styles.checkButton}>
                        <img src={phone} alt="Phone Icon" />
                        {PhoneNumber? PhoneNumber : isContactRequested  }
                      </span>
                    ) : (
                      <span>Request/Show Mobile Number</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.specificationsContainer}>
              <div className={styles.specificationsTable}>
                <h3 className={styles.sectionTitle}>Specifications</h3>
                <table className={styles.specTable}>
                  <tbody>
                    {product.specifications.map((specifications) => (
                      <tr>
                        <td className={styles.specLabel}>
                          {specifications.title}
                        </td>
                        <td className={styles.specValue}>
                          {specifications.detail}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className={styles.vendorDetails}>
              <div className={styles.vendorHeader}>
                <p>Sold By</p>
              </div>
              <div className={styles.vendorName}>
                <img
                  src={defaultImage}
                  alt="Vendor"
                  className={styles.vendorImage}
                />
                <p>{product.vendorName}</p>
              </div>
              <div className={styles.vendorProduct}>
                <p>90 Products</p>
              </div>
              <button className={styles.viewShopButton} onClick={() =>
                  viewCategoryPage(product.vendorId)
                  }
                > View Shop</button>            </div>
          </div>
        </div>
        {/* Add Specifications Table */}

        <div className={styles.reviewFill}>
          <div className={styles.ratingDistribution}>
          {ratings.length > 0 
  ? ratings.map((ratingObj, index) => (
      <div key={index} className={styles.ratingBar}>
        <span className={styles.starCount}> {ratingObj.rating} ★</span>
      <div className={styles.progressBar}>
          <div 
            className={styles.progressFill}
            style={{
              width: rate,
              display: 'flex',
              color: 'gold',
              zIndex: 1000,
              position: 'relative'
            }}
          />
        </div>
        <span className={styles.ratingCount}>
          {ratingObj.ratingCount} Reviews
        </span>
      </div>
    ))
  : [1, 2, 3, 4, 5].map((rating) => (
      <div key={rating} className={styles.ratingBar}>
        <span className={styles.starCount}> {rating} ★</span>
        <div className={styles.progressBar}>
          <div 
            className={styles.progressFill}
            style={{
              width: '0%',
              display: 'flex',
              color: 'gold',
              zIndex: 1000,
              position: 'relative'
            }}
          />
        </div>
        <span className={styles.ratingCount}>
          0 Reviews
        </span>
      </div>
    ))
}
          </div>
          <div className={styles.reviewFormContainer}>
            <h3 className={styles.reviewFormTitle}>Write a Review</h3>
            <form className={styles.reviewForm}>
              <div className={styles.ratingInput}>
                <label>Rate this product</label>
                <div className={styles.ratingStars}>
                  {[1, 2, 3, 4, 5].map((star) => (
                    <span
                      key={star}
                      className={`${styles.star} ${
                        star <= rating ? styles.filled : ""
                      }`}
                      onClick={() => setRating(star)}
                    >
                      ★
                    </span>
                  ))}
                </div>
              </div>
              <textarea
                className={styles.reviewTextArea}
                placeholder="Share your experience with this product..."
                value={reviewText}
                onChange={(e) => setReviewText(e.target.value)}
              />
              <button type="submit" className={styles.submitReview} onClick={handleAddRating}>
                Submit Review
              </button>
            </form>
          </div>
        </div>
        {/* Reviews Section */}
        <div className={styles.reviewsContainer}>
          <h3 className={styles.reviewsTitle}>Customer Reviews</h3>

          <div className={styles.overallRating}>
            <div className={styles.ratingStats}>
              <div className={styles.avgRating}>{product.averageRating}</div>
              <div className={styles.starsLarge}>
                <ReactStars
                  count={5}
                  value={parseFloat(product.averageRating)}
                  key={product.averageRating}
                  size={24}
                  // starRatedColor="gold"
                  isHalf={true}
                  edit={false}
                />
              </div>
              <div className={styles.totalReviews}>
                Based on {product.ratingCount} Reviews
              </div>
            </div>
          </div>

          {/* Review Comments */}
          <div className={styles.reviewsList}>
            {(showAllReview ? reviews : reviews.slice(0, 4)).map(
              (review, index) => (
                <div key={index} className={styles.reviewItem}>
                  <div className={styles.reviewHeader}>
                    <div className={styles.reviewerInfo}>
                      <FaUser className={styles.userIcon} />
                      <span className={styles.reviewerName}>
                        {review.vendorName}
                      </span>
                    </div>
                    <div className={styles.reviewRating}>
                      <div className={styles.stars}>
                        <ReactStars
                          key={review.rating}
                          count={5}
                          value={review.rating}
                          size={24}
                          starRatedColor="gold"
                          isHalf={true}
                          edit={false}
                        />
                      </div>
                      <div className={styles.reviewDate}>
                        {review.createdAt}
                      </div>
                    </div>
                  </div>
                  <p className={styles.reviewText}>{review.review} </p>
                </div>
              )
            )}
            {!showAllReview && reviews.length > 4 && (
              <button className={styles.viewAllButton} onClick={viewAllReview}>
                View All Reviews
              </button>
            )}
          </div>
        </div>

        {/* Review Form */}

        {/* Rating Distribution */}
      </div>
    </div>
  );
};

export default DetailInventory;
