import React from 'react';
import styles from './trialModal.module.css';
import trialImage from '../../Assest/img/trailPeriod.svg';

const TrialModal = ({ onClose }) => {
  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <button className={styles.closeButton} onClick={onClose}>×</button>
        <div className={styles.modalBody}>
          <img src={trialImage} alt="Trial Period" className={styles.trialImage} />
          <p className={styles.message}>Your one month trial period has been started.</p>
        </div>
      </div>
    </div>
  );
};

export default TrialModal; 