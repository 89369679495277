import React  from 'react';
import Layout from '../Layout/Layout';
import OrderDetail from '../../orders/OrderDetail';
function createDetailFlow() {


  return (
    <Layout  child={'Order Detail'}>
          <OrderDetail/>
  </Layout>
  )
}

export default createDetailFlow
