import React  from 'react';
import Layout from '../Layout/Layout';
import Order from '../../orders/Order';

function orderFlow() {


  return (
    <Layout  child={'Order'}>
    <Order />
  </Layout>
  )
}

export default orderFlow