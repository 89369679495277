import React from "react";
import Layout from "../Layout/Layout";
import Wishlist from "../../Ecom/Wishlist";

function WishlistFlow() {
  return (
    <Layout child="Manage Inventory">
    <Wishlist />
    </Layout>
  );
}

export default WishlistFlow;
