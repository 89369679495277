import React from 'react';
import Layout from "../Layout/Layout";
import AddEcomInventory from '../../Ecom/AddEcomInventory';

function EcomAddInventoryFlow() {
    return (
        <Layout child="Add inventory">
            <AddEcomInventory />
        </Layout>
    );
}

export default EcomAddInventoryFlow;
