import React  from 'react';
import Layout from '../Layout/Layout';
import AddParty from '../../party/AddParty';

function AddPartyFlow() {


  return (
    <Layout  child={'Add Party'}>
    <AddParty />
  </Layout>
  )
}

export default AddPartyFlow
