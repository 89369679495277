import React from 'react';
import Layout from '../Layout/Layout';
import EditProfile from '../../Home/EditProfile';

function ProfileFlow() {
  return (
    <Layout  child={'Edit Profile'}>
    <EditProfile />
  </Layout>
  );
}

export default ProfileFlow;
