import React  from 'react';
import Layout from '../Layout/Layout';
import AddItem from '../../orders/AddItem';

function addOrderFlow() {


  return (
    <Layout  child={'Add Order Item'}>
    <AddItem/>
  </Layout>
  )
}

export default addOrderFlow