import React, { useEffect, useState } from "react";
import save from "../../Assest/img/save.svg";
import cancel from "../../Assest/img/cancel.svg";
import { useNavigate } from "react-router";
import {
  addInventory,
  getCategoryList,
  godownList,
} from "../../api/inventoryAPI";
import { useSelector } from "react-redux";
import styles from "./addinventory.module.css";
import deleteImg from "../../Assest/img/deleteImg.svg";
import AddGodownModal from "./AddGodownModal";
import AddCategoryModal from "./AddCategoryModal";
import { toast } from "react-toastify";

function AddInventory() {
  const navigate = useNavigate();
  const [data, setData] = useState({
    description: "",
    uniqueId: "",
    name: "",
    availableQuantity: "",
    totalQuantity: "",
    purchasePrice: "",
    dayRent: "",
    discount: "",
    categoryId: "",
    godownAddition: "",
    img: "",
  });
  const [category, setCategory] = useState([]);
  const [showSelectGodown, setShowSelectGodown] = useState(false);
  const [godown, setGodown] = useState([]);
  const [godownId, setGodownId] = useState(null);
  const [godownQuantity, setGodownQuantity] = useState(null);
  const [godownName, setGodownName] = useState("");
  const [showGodownData, setShowGodownData] = useState([]);
  const [available, setAvailable] = useState(null);
  const [openAddGodownModal, setOpenAddGodownModal] = useState(false);
  const [openAddCategoryModal, setOpenAddCategoryModal] = useState(false);

  const token = useSelector((state) => state.login.token);

  const totalSum = showGodownData.reduce(
    (sum, godown) => sum + Number(godown.quantity),
    0
  );

  useEffect(() => {
    const fetchCategories = async () => {
      const categories = await getCategoryList(token);
      setCategory(categories.data);
    };
    const fetchGodown = async () => {
      const godowns = await godownList(token);
      setGodown(godowns.data);
    };
    if (totalSum &&
      totalSum >= Number(data.availableQuantity) 
    ) {
      setData(prev => ({...prev, availableQuantity: totalSum}))
      console.log(totalSum, Number(data.availableQuantity));
      // alert('godown Quantity is more then Available Quantity')
    }
    
    fetchCategories();
    fetchGodown();
  }, [token, totalSum, data.availableQuantity]);

  const handleAddInventory = async () => {
    try {
      data.godownAddition = JSON.stringify(showGodownData);
      console.log(data);
      setData((prev) => ({ ...prev, totalQuantity: data.availableQuantity }));

      await addInventory(token, data);
      navigate("/category");
    } catch (error) {
      console.error("Failed to add inventory:", error);
    }
  };

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setData((prevData) => ({ ...prevData, img: file }));
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((prev) => ({ ...prev, [name]: value }));
    console.log(value);
  };

  const handleGodownId = (e) => {
    const id = e.target.value;
    setGodownId(id);
    const name = godown.find((e) => e.godownId === +id);
    setGodownName(name.godownName);
  };
  const handleGodownQuantity = (e) => {
    setGodownQuantity(e.target.value);
  };
  const handleSaveGodown = () => {
    setShowGodownData((pre) => [
      ...pre,
      { godownId, quantity: godownQuantity, godownName },
    ]);
    setGodownId("");
    setGodownQuantity("");
    setGodownName("");
  };

  const handleShowGodown = () => {
    setShowSelectGodown(true);
  };
  const handleDeleteSelectedGodown = (index) => {
    setShowGodownData((prevGodowns) =>
      prevGodowns.filter((_, i) => i !== index)
    );
  };

  const handleOpenAddGodownModal = () => {
    setOpenAddGodownModal(true);
  };
  const handleCloseAddGodownModal = () => {
    setOpenAddGodownModal(false);
  };
  const handleOpenAddCategoryModal = () => {
    setOpenAddCategoryModal(true);
  };
  const handleCloseAddCategoryModal = () => {
    setOpenAddCategoryModal(false);
  };
  const updateData = async () => {
    try {
      const categories = await getCategoryList(token);
      setCategory(categories.data);
      const godowns = await godownList(token);
      setGodown(godowns.data);
    } catch (error) {
      console.error("Failed to fetch order details:", error);
    }
  };

  const back = () => {
    navigate("/category");
  };
  return (
    <div className={styles.inventoryDetailContainer}>
      {" "}
      {/* Module styling */}
      <div className={styles.mainEdit}>
        <div className={styles.leftEdit}>
          <img
            src={data.img ? URL.createObjectURL(data.img) : ""}
            alt="Inventory"
          />
          <input type="file" onChange={handleImageChange} />
        </div>
        <div className={styles.rightEdit}>
          <div className={styles.cardEdit}>
            <div className={styles.headEdit}>
              <p>Add Item</p>
             
            </div>
            <div className={styles.infoEdit}>
              <div className={styles.item}>
                <div>
                  <label>Item Name</label>
                  <input
                    type="text"
                    value={data.name}
                    onChange={handleChange}
                    name="name"
                  />
                </div>
                <div>
                  <label>Item Number</label>
                  <input
                    type="number"
                    value={data.uniqueId}
                    onChange={handleChange}
                    name="uniqueId"
                  />
                </div>
              </div>
              <div className={styles.availUnit}>
                <div className={styles.available}>
                  <label>Available</label>
                  <input
                    type="number"
                    value={data.availableQuantity }
                    onChange={handleChange}
                    name="availableQuantity"
                    defaultValue=""
                  />
                </div>
                <div className={styles.unit}>
                  <label>Unit</label>
                  <select
                    type="text"
                    value={data.unit}
                    onChange={handleChange}
                    name="unit"
                    defaultValue=""
                  >
                                      <option value="" disabled> Select Unit</option>

                      <option value="piece">piece</option>
                      <option value="kg">kg</option>
                      <option value="litre">Square feet</option>
                    </select>
                  

                </div>
              </div>
              <div className={styles.item}>
                <div>
                  <label>Purchase Rs</label>
                  <input
                    className={styles.price}
                    type="number"
                    value={data.purchasePrice}
                    onChange={handleChange}
                    name="purchasePrice"
                  />
                </div>
                <div>
                  <label>Rent Price</label>
                  <input
                    className={styles.price}
                    type="number"
                    value={data.dayRent}
                    onChange={handleChange}
                    name="dayRent"
                  />
                </div>
                <div>
                  <label>Discount</label>
                  <input
                    className={styles.in}
                    value={data.discount}
                    onChange={handleChange}
                    name="discount"
                  />
                </div>
              </div>
              <div className={styles.category}>
                <label>Category:</label>
                <select
                  name="categoryId"
                  value={data.categoryId}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>
                    Select Category
                  </option>
                  {category.map((cat) => (
                    <option key={cat.categoryId} value={cat.categoryId}>
                      {cat.name}
                    </option>
                  ))}
                </select>
                <button
                  className={styles.addCat }
                  onClick={handleOpenAddCategoryModal}
                >
                  Add New Category
                </button>
              </div>
            </div>
            <div className={!showSelectGodown ? styles.add :styles.addAfter}>
            <div className={styles.godown}>
              {!showSelectGodown && (
                <button
                  className={styles.selectGodown}
                  onClick={handleShowGodown}
                >
                  Select Godown
                </button>
              )}

              {showSelectGodown && (
                <div className={styles.select}>
                  <div className={styles.go}>
                    <select
                      name="godownId"
                      value={godownId || ''}
                      onChange={handleGodownId}
                      required
                    >
                      <option value="" disabled>
                        Select godown
                      </option>
                      {godown.map((godown) => (
                        <option key={godown.godownId} value={godown.godownId}>
                          {godown.godownName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <input
                    className={styles.input}
                    type="number"
                    onChange={handleGodownQuantity}
                    value={godownQuantity}
                  ></input>

                  <button
                    className={styles.save}
                    disabled={!godownId || !godownQuantity}
                    onClick={handleSaveGodown}
                  >
                    save
                  </button>
                </div>
              )}

              {showGodownData.length != 0 ? (
                <div className={styles.items}>
                  <div>
                    <label>Godown</label>
                  </div>
                  <div>
                    <label>Quantity</label>
                  </div>
                </div>
              ) : (
                ""
              )}

              {showGodownData.map((e, index) => (
                <div className={styles.item}>
                  <div>
                    <input value={e.godownName} />
                  </div>
                  <div>
                    <input value={e.quantity} />
                  </div>
                  <div>
                    <img
                      src={deleteImg}
                      onClick={() => handleDeleteSelectedGodown(index)}
                    ></img>
                  </div>
                </div>
              ))}
              </div>
              <div  className={styles.addGodown}>

              <button
               
                onClick={handleOpenAddGodownModal}
                >
                {" "}
                Add Godown
              </button>
                </div>
            </div>
            <AddGodownModal
              openModal={openAddGodownModal}
              closeModal={handleCloseAddGodownModal}
              updateData={updateData}
            />
            <AddCategoryModal
              openModal={openAddCategoryModal}
              closeModal={handleCloseAddCategoryModal}
              updateData={updateData}
            />
          </div>
          <div className={styles.saveCancel}>
                <button className={styles.delete} onClick={back}>
                 Cancel
                </button>
                <button className={styles.edit} onClick={handleAddInventory}>
                  Save
                </button>
              </div>
        </div>
      </div>
    </div>
  );
}

export default AddInventory;
