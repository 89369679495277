import React from "react";
import Layout from "../Layout/Layout";
import Inventory from "../../Ecom/Inventory";

function InventoryFlow() {
  return (
    <Layout child="Inventory">
      <Inventory />
    </Layout>
  );
}

export default InventoryFlow;
