import React, { useEffect, useState } from "react";
import styles from "./AddEcomInventory.module.css";
import { editEcomInventory, productDetail } from "../../api/ecom";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {getCategoryList} from '../../api/inventoryAPI'
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { editInventory } from "../../api/ecom";

function EditEcomInventory() {
  const navigate = useNavigate();
  const location = useLocation();
  const productId = location.state || '';
  const token = useSelector((state) => state.login.token);

  const [images, setImages] = useState([]);
  const [rows, setRows] = useState([{ }]);

  const [inventoryData, setInventoryData] = useState({
    images: [],
    specifications: [],
    productName: '',
    categoryId: '',
    pricing: '',
    discountedPrice: '',
    description: ''
  });
  const [category, setCategory] = useState([]);

  // Fetch inventory detail
  useEffect(() => {
    const detail = async () => {
      try {
        const response = await productDetail(token, productId.productId);
        setInventoryData(response.data);
      } catch (error) {
        console.error('Error fetching product details:', error);
        toast.error('Failed to fetch product details');
      }
    };
    detail();
  }, [token, productId]);

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    
    if (files.length + inventoryData.images.length > 4) { 
      toast.error("You can only upload up to 4 images");
      return;
    }

    const imagePreviews = files.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
      url: URL.createObjectURL(file)
    }));

    setInventoryData(prev => ({
      ...prev,
      images: [...prev.images, ...imagePreviews]
    }));
  };

  const handleRemoveImage = (index) => {
    if (inventoryData.images[index].preview) {
      URL.revokeObjectURL(inventoryData.images[index].preview);
    }
    
    setInventoryData(prev => ({
      ...prev,
      images: prev.images.filter((_, i) => i !== index)
    }));
  };

  //specifications
  const handleRowChange = (index, field, value) => {
    setInventoryData(prev => {
      const updatedSpecs = [...prev.specifications];
      updatedSpecs[index] = {
        ...updatedSpecs[index],
        [field]: value
      };
      return {
        ...prev,
        specifications: updatedSpecs
      };
    });
  };

  const handleAddRow = (e) => {
    e.preventDefault();
    setInventoryData(prev => ({
      ...prev,
      specifications: [...prev.specifications, { title: '', detail: '' }]
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInventoryData(prev => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categories = await getCategoryList(token);
        setCategory(categories.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
        toast.error('Failed to fetch categories');
      }
    };
    fetchCategories();
  }, [token]);

  const updateInventory = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();

      // Add basic data
      formData.append('productName', inventoryData.productName);
      formData.append('categoryId', inventoryData.categoryId);
      formData.append('pricing', inventoryData.pricing);
      formData.append('discountedPrice', inventoryData.discountedPrice);
      formData.append('description', inventoryData.description);

      // Add specifications
      formData.append('specifications', JSON.stringify(inventoryData.specifications));

      // Add images
      inventoryData.images.forEach((image, index) => {
        if (image.file) {
          formData.append('productImages', image.file);
        }
      });

      await editInventory(token, productId.productId, formData);
      toast.success('Inventory updated successfully!');
      navigate('/manageInventory');
    } catch (error) {
      console.error('Error updating inventory:', error);
      toast.error('Failed to update inventory');
    }
  };

   

  return (
    <div className={styles.container}>
      <div className={styles.formContainer}>
        <form>
          <div className={styles.section}>
            <h3>Product Info</h3>

            <div className={styles.inputGroup}>
              <label>Product Name</label>
              <input
                type="text"
                name="productName"
                value={inventoryData.productName}
                onChange={handleChange}
              />
            </div>

            <div className={styles.inputGroup}>
              <label>Category</label>
              <select
                className={styles.selectBox}
                name="categoryId"
                value={inventoryData.categoryId || ''}
                onChange={handleChange}
                          >
                <option value="" disabled>
                  Select Category
                </option>
                {category.map((cat) => (
                  <option key={cat.categoryId} value={cat.categoryId}>
                    {cat.name}
                  </option>
                ))}
              </select>
            </div>

            <div className={styles.inputGroup}>
              <label>Product Media</label>
              <p className={styles.mediaNote}>You can add up to 4 images</p>
              <div className={styles.imageUpload}>
                {inventoryData.images.map((img, index) => (
                  <div key={index} className={styles.previewImage}>
                    <img 
                      src={img.url || img.preview || img} 
                      alt={`Preview ${index + 1}`} 
                    />
                    <button 
                      type="button"
                      className={styles.remove} 
                      onClick={() => handleRemoveImage(index)}
                    >
                      Remove
                    </button>
                  </div>
                ))}
                {inventoryData.images.length < 4 && (
                  <div className={styles.uploadButton}>
                    <input
                      type="file"
                      accept="image/*"
                      multiple
                      onChange={handleImageUpload}
                      name="productImages"
                    />
                    <span>Upload Image</span>
                  </div>
                )}
              </div>
            </div>

            <div className={styles.input}>
              <div>
                <label>Pricing</label>
                <input
                  type="number"
                  name="pricing"
                  value={inventoryData.pricing}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label>Discounted Price</label>
                <input
                  type="number"
                  name="discountedPrice"
                  value={inventoryData.discountedPrice}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className={styles.inputGroup}>
              <label>Description</label>
              <textarea
                name="description"
                value={inventoryData.description}
                onChange={handleChange}
              />
            </div>

            <div className={styles.productDetails}>
              <h2>Product Details</h2>
              <div className={styles.tabletitle}>
                <p>Title</p>
                <p>Detail</p>
              </div>
              <div className={styles.detailsTable}>
                {inventoryData.specifications.map((spec, index) => (
                  <div className={styles.detailRow} key={index}>
                    <div className={styles.detailTitle}>
                      <input
                        type="text"
                        value={spec.title || ''}
                        onChange={(e) => handleRowChange(index, 'title', e.target.value)}
                      />
                    </div>
                    <div className={styles.detailValue}>
                      <input 
                        type="text"
                        value={spec.detail || ''}
                        onChange={(e) => handleRowChange(index, 'detail', e.target.value)}
                      />
                    </div>
                  </div>
                ))}
                <button onClick={handleAddRow}>Add</button>
              </div>
            </div>
            <div className={styles.buttonGroup}>
              <button
                type="submit"
                className={styles.createButton}
                onClick={updateInventory}
              >
                Edit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditEcomInventory;
