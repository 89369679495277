import React  from 'react';
import Layout from '../Layout/Layout';
import EditCreateOrder from '../../orders/EditCreateOrder';

function EditCreateOrderFlow() {


  return (
    <Layout  child={'Edit Order'}>
    <EditCreateOrder />
  </Layout>
  )
}

export default EditCreateOrderFlow